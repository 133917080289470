import React, { useEffect, useState } from "react";
import "../../../assets/css/receipt.css";
import Logo from "../../elements/Logo";
import { getUser } from "../../../config/common";
import { ToastContainer, toast } from 'react-toastify';
import moment from "moment";

const PrintReceipt = (data) => {
  const [printData, setPrintData] = useState([]);
  const [user, setUser] = useState([]);
  const [type, setType] = useState('');
  const [discount, setDiscount] = useState({});
  const [show, setShow] = useState(false);
  useEffect(() => {
    setPrintData(data.printData[0]);
    const userData = getUser();
    setUser(userData);
    setType(data.printData[1]);
    setDiscount(data.printData[2]);
  }, [data]);

  console.log(discount)

  const saveCartToLocalStorage = (cart) => {
    const id = Math.random().toString(36).substr(2, 9);
    const cartId = id;
    const name = 'Order-'.concat(id);
    let cartItems = [];
    if (localStorage.getItem("cartItems") === null) {
      cartItems = [];
    } else {
      cartItems = JSON.parse(localStorage.getItem("cartItems"));
    }
    const cartItem = {
      cartId: cartId,
      name: name,
      cart,
    }
    // cartItems.push(cartItem);
    localStorage.setItem("cartItems", JSON.stringify(cartItems));

    toast.success("Order Saved Successfully");
  };

  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const today = new Date();
  const date = today.toLocaleDateString("en-US", options);

  const totalAmount = printData.reduce((a, c) => a + c.price * c.quantity, 0);

  return (
    <div className="m-0 ">
      <div className="pos-modal">
        <ToastContainer />
        <div className="pos-modal-content">
          <div className="pos-modal-body">
            <div className="pos-modal-confirm">
              <div className="pos-modal-confirm-body pos-modal-confirm-body-render">
                <div className="print-modal">
                  <div className="receipt-logo">
                    <Logo/>
                  </div>
                  <h3 className="text-center mb16">{user?.businessName}</h3>
                  <div className="text-center mb16">
                    <strong>3, Olukoleosho street, Off Sibiat Abiola way</strong>
                    <div>
                      <div>
                        Ikeja, Lagos
                      </div>
                      <div>{user?.email} </div>
                    </div>
                  </div>

                  <>
                    <h3 className="mb16">{type === 'print' ? (
                      'Bill'
                    ) : (
                      'Invoice'
                    )} <span style={{ float: 'right' }}>#2456</span></h3><p className="mb16">DD-MM-YYYY <span style={{ float: 'right' }}>EMPLOYEE:</span></p><p className="mb16">{moment(date).format("MMM Do YY")} <span style={{ float: 'right' }}>{user?.firstName}</span></p><div className="mt16 mb16">
                      <div className="row receipt-column">
                        {printData.map((cart) => (

                            <><div className="col-md-2">X{cart.quantity}</div><div className="col-md-5 receipt-column-product">{cart.cart?.product?.name}</div><div className="col-md-5 receipt-column-date">{cart.cart?.product?.createdAt}</div></>

                        ))}
                      </div>
                    </div><div className="mt16 mb16">
                      <div className="row receipt-column">
                        <div className="col-md-2"></div>
                        <div className="col-md-5 receipt-column-product">DIscount:</div>
                        {discount.value ? (
                        <div className="col-md-5 receipt-column-date">{discount.type === "percentage" ? (
                         discount.value + ' %'
                        ) : (
                          discount.value + 'amount applied'
                        )}
                        </div>
                        ): (
                         <div className="col-md-5 receipt-column-date"> 0 </div>
                        )}
                        <div className="col-md-2"></div>
                        <div className="col-md-5 receipt-column-product">VAT:</div>
                        <div className="col-md-5 receipt-column-date">5</div>
                        <div className="col-md-2"></div>
                        <div className="col-md-5 receipt-column-product">Total Due:</div>
                        {discount.type === "percentage" ? (
                            <div className="col-md-5 receipt-column-date">₦ {discount.value ? (totalAmount - (totalAmount * discount.value / 100)) : totalAmount}
                            </div>
                            ) : (
                              <div className="col-md-5 receipt-column-date">₦ {discount.value ? (totalAmount - (discount.value)) : totalAmount}</div>
                            )}
                        <p style={{ textAlign: 'left', marginTop: '20px' }}>Payment Methods:  Cash, Bank, POS, Business Account</p>
                      </div>
                    </div></>
                </div>
              </div>
            </div>
            <div className="pos-modal-confirm-footer">
              <button onClick={() => setShow(false)} type="button" className="pos-btn pos-btn-text">
                <span>Cancel</span>
              </button>
              {type === 'print' ? (
                <button type="button" className="pos-btn pos-btn-primary">
                  <span>Print</span>
                </button>
              ) : (
                <button onClick={() => saveCartToLocalStorage(printData)} type="button" className="pos-btn pos-btn-primary">
                  <span>Save and Print</span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintReceipt;
