import request from 'umi-request';
import { API_URL, USER_TOKEN } from '../config/config';

export async function getAllSuppliers(userId, ref) {
  return request(`${API_URL}/api/supplier/${userId}?businessRef=${ref}`, {
    method: 'get',
    headers: {
       'Content-Type': 'application/json',
       'Authorization': USER_TOKEN,
    },
  });
}


export async function getSupplier(userId, id) {
  return request(`${API_URL}/api/supplier/?id=${id}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
    // params: {
    //   userId: userId,
    //   id: id,
    // },
  });
}

export async function createSupplier(body, userId, buinessRef) {
  return request(`${API_URL}/api/supplier/${userId}?businessRef=${buinessRef}`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
    body: JSON.stringify(body),
  });
}

export async function updateSupplier(body, userId, customerId) {
  return request(`${API_URL}/api/supplier/${userId}?id=${customerId}`, {
    method: 'patch',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
    body: JSON.stringify(body),
  });
}


export async function deleteSupplier(userId, customerId, ref) {
  return request(`${API_URL}/api/supplier/${userId}?id=${customerId}&ref=${ref}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
    params: {
      id: userId,
      ref: ref,
    },
  });
}
