import React from 'react';
import { Container } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import Login from '../../components/Auth/Login';
import { getUser } from '../../config/common';

const LoginView = () => {

  if (getUser()) {
    window.location.href = '/dashboard';
     toast.success('You are already logged in');
  }

  return (
    <>
    <Container fluid className='authClass'>
      <ToastContainer />
      <Login/>
      </Container>
    </>
  );
}
export default LoginView;