import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
// import { Collapse } from 'react-bootstrap';
import { Trans } from "react-i18next";

class Sidebar extends Component {
  state = {};
  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: true });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }


  onRouteChanged() {
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });
  }

  toggler() {
    if (this?.isPathActive("/dashboard")) {
      if (document.body.classList.contains("sidebar-icon-only")) {
      document.body.classList.toggle("marginToggle");
      document.body.classList.toggle("sidebar-icon-only");
      }
    }
    
  }

  render() {
    this.toggler();
    if (this.isPathActive("/dashboard")) {
      if (document.body.classList.contains("sidebar-icon-only")) {
        document.body.classList.toggle("sidebar-icon-only");
      }
    }
    return (
      <nav
        className="sidebar sidebar-offcanvas dbg"
        id="sidebar"
        style={{ minHeight: "97vh" }}
      >
        <ul className="nav p-0 pt-3 w-100 get">
          <li className={"nav-item"}>
            <Link className="nav-link" to="#">
              <i onClick={this.toggler} className="mdi mdi-menu menu-icon"></i>
            </Link>
          </li>

          <li
            className={
              this.isPathActive("/dashboard") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/dashboard">
              <i className="mdi mdi-home-outline menu-icon"></i>
              <span className="menu-title">
                <Trans> Home</Trans>
              </span>
            </Link>
          </li>

          <li
            className={
              this.isPathActive("/pos") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/pos">
              <i className="mdi mdi-dns menu-icon"></i>
              <span className="menu-title">
                <Trans> Point of Sales</Trans>
              </span>
            </Link>
          </li>

          <li
            className={
              this.isPathActive("/sales") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/sales/main-sales">
              <i className="mdi mdi-shopping menu-icon"></i>
              <span className="menu-title">
                <Trans> Sales </Trans>
              </span>
            </Link>
          </li>

          <li
            className={
              this.isPathActive("/products") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/products">
              <i className="mdi mdi-cart-outline menu-icon"></i>
              <span className="menu-title">
                <Trans> Products </Trans>
              </span>
            </Link>
          </li>

          <li
            className={
              this.isPathActive("/purchases") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/purchase/main-purchase">
              <i className="mdi mdi-cart-outline menu-icon"></i>
              <span className="menu-title">
                <Trans> Purchases </Trans>
              </span>
            </Link>
          </li>

          <li
            className={
              this.isPathActive("/inventory") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/dashboard">
              <i className="mdi mdi-format-list-bulleted menu-icon"></i>
              <span className="menu-title">
                <Trans> Inventory </Trans>
              </span>
            </Link>
          </li>

          <li
            className={
              this.isPathActive("/finance") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/dashboard">
              <i className="mdi mdi-credit-card menu-icon"></i>
              <span className="menu-title">
                <Trans> Financial Overview </Trans>
              </span>
            </Link>
          </li>

          <li
            className={
              this.isPathActive("/supplier") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/supplier">
              <i className="mdi mdi-truck menu-icon"></i>
              <span className="menu-title">
                <Trans> Suppliers </Trans>
              </span>
            </Link>
          </li>

          <li
            className={
              this.isPathActive("/return-orders") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/return-orders">
              <i className="mdi mdi-chevron-down menu-icon"></i>
              <span className="menu-title">
                <Trans> Return Orders </Trans>
              </span>
            </Link>
          </li>

          <li
            className={
              this.isPathActive("/report") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/dashboard">
              <i className="mdi mdi-file-chart menu-icon"></i>
              <span className="menu-title">
                <Trans> Report </Trans>
              </span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/customers") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/customers">
              <i className="mdi mdi-account-multiple menu-icon"></i>
              <span className="menu-title">
                <Trans> Customers </Trans>
              </span>
            </Link>
          </li>

          <li
            className={
              this.isPathActive("/expenses") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/expenses">
              <i className="mdi mdi-finance menu-icon"></i>
              <span className="menu-title">
                <Trans> Expenses </Trans>
              </span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/riders") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/riders">
              <i className="mdi mdi-bike menu-icon"></i>
              <span className="menu-title">
                <Trans> Riders </Trans>
              </span>
            </Link>
          </li>
          <li
            className={
              this.isPathActive("/users") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/users">
              <i className="mdi mdi-account-multiple menu-icon"></i>
              <span className="menu-title">
                <Trans> Users </Trans>
              </span>
            </Link>
          </li>
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}

export default withRouter(Sidebar);
