import React, { useState, useEffect } from "react";
import "../../../App.scss";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import CreateExpense from "./CreateExpenses";
import { deleteExpense, getAllExpenses } from "../../../services/ExpenseService.js";
import { getUser } from '../../../config/common';
const Expenses = () => {
  const [show, setShow] = useState(false);
  const [createExpenseModal, setCreateExpenseModal] = useState(false);
  const [editExpenseModal, setEditExpenseModal] = useState(false);
  const [expenses, setExpenses] = useState([]);
  const [expenseData, setExpenseData] = useState({});
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const showFundExpenseModal = () => setShow(true);
  const closeExpenseModal = () => setCreateExpenseModal(false);
  const showCreateExpenseModal = () => setCreateExpenseModal(true);
  useEffect(() => {
    async function fetchData() {
        setLoading(true);
        const user = await getUser();
        if (user) {
            const userId = user._id;
            const response = await getAllExpenses(userId, user.businessRef);
            setExpenses(response.data);
            setUser(user);
            setLoading(false);

        }
    }
    fetchData();

}, []);

const removeExpense = async (id) => {
  const user = await getUser();
  const result = window.confirm("Are you sure you want to delete this expense?");
  if (result) {
  if (user) {
    await deleteExpense(id);
      const newExpense = expenses.filter(expense => expense._id !== id);
      setExpenses(newExpense);
  }
  }
};

  const showEditModal = (data) => {
    setEditExpenseModal(true);
    setExpenseData(data);
  };

  return (
    <div className="m-0">
      <h6 className=" mb-4 col-12">
        <span>
          <i className="mdi mdi-account-multiple "></i>
        </span>{" "}
        Expenses{" "}
      </h6>

      <div className=" heads shadow-sm border h-75  p-3">
        <div className="  text-right  ">
          <Link
            to="#"
            onClick={showCreateExpenseModal}
            className="btn btn-primary mr-2 r4 text-white btn-sm p-2 px-4"
          >
            {" "}
            Add Expense{" "}
          </Link>
        </div>
        <hr className="shadow mt-0 pt-0 mt-2" />
        <div className="body">
          <div className="header text-right">
            <span className="mdi db-query mdi-magnify"></span>
            <input type="text" className="db-search pl-4" />
          </div>
          <div className="views mt-3 shadow-sm rounded-lg">
            {expenses.length === 0 ? (
              <div className="empty-view" style={{textAlign: 'center'}}>
                <div className="title">No Expenses</div>
                <div className="description">
                  You have no expenses. You can add a expense by clicking the
                  button above.
                </div>
              </div>
            ) : (
              <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Title</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Status</th>
                  <th scope="col">Category</th>
                  <th scope="col">Details</th>
                  <th scope="col">Date</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {expenses.map((expense) => (
                <tr key={expense._id}>
                  <th scope="row"><input type={'checkbox'}></input></th>
                  <td>{expense.title}</td>
                  <td>{expense.amount}</td>
                  <td>{expense.status}</td>
                  <td>{expense.category}</td>
                  <td>{expense.details}</td>
                  <td>{expense.date}</td>
                  <td>
                    <span className="mdi mdi-pencil btn btn-primary text-white btn-md mx-1 r4" title="Edit"
                    onClick={() => showEditModal(expense)}></span>
                    <span onClick={() => removeExpense(expense._id)} className="mdi mdi-delete btn btn-danger text-white btn-md mx-1 r4" title="Delete"></span>
                  </td>
                </tr>
              ))} 
              </tbody>
            </table>
            </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        show={createExpenseModal}
        onHide={closeExpenseModal}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="md"
        style={{ width: "100%", borderRadius: "50px" }}
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>

        <CreateExpense type={'create'}/>

        </Modal.Body>
      </Modal>


      <Modal
        show={editExpenseModal}
        onHide={() => setEditExpenseModal(false)}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="md"
        style={{ width: "100%", borderRadius: "50px" }}
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>

        <CreateExpense type={'update'} data={expenseData}/>

        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Expenses;
