import React, { useState, useEffect, useCallback, useMemo } from "react";
import "../../../App.scss";
import "../../../assets/css/dashboard.css";
import { createProduct, updateProduct } from "../../../services/ProductService";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUser } from '../../../config/common';
import CreatableSelect from 'react-select/creatable';
import { getAllProducts } from "../../../services/ProductService";
import { Widget } from '@uploadcare/react-widget';
import { UPLOAD_CARE_PUBLIC_KEY } from "../../../config/config";

const CreateProduct = (data) => {
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [trackProductStock, setTrackProductStock] = useState(false);
  const [productVariant, setProductVariant] = useState(false);
  const [tax, setTax] = useState(false);
  const [products, setProducts] = useState([]);
  const [expiryDate, setExpiryDate] = useState(false);
  const [lowStock, setLowStock] = useState(false);
  const [hideInPOS, setHideInPOS] = useState(false);
  const [hideInShop, setHideInShop] = useState(false);
  const [warehouse, setWarehouse] = useState(false);
  const [isService, setService] = useState(false);
  const [isProduct, setProduct] = useState(false);
  const [totalVariantQuantity, setTotalVariantQuantity] = useState(0);
  const [formState, setFormState] = useState({
    type: "",
    name: "",
    description: "",
    category: "",
    cost: 0,
    selling: 0,
    units: 0,
    amount_per_unit: 0,
    quantity: 0,
    low_stock: false,
    imageUrl: '',
    variant: "",
    stockUnit: "",
    stockQuantity: 0,
  });

  const [variants, setVariants] = useState({
    variantType: "",
    variantName: "",
    variantCostPrice: 0,
    variantSellingPrice: 0,
    variantQuantity: 0,
  });

  const productData = data.data || {}; 

  useMemo (() => {
    if (productData._id) {
    setFormState({
      ...formState,
      full_name: productData.full_name || '',
      company: productData.company || '',
      phone: productData.phone || '',
      email: productData.email || '',
      address: productData.address || '',
      country: productData.country || '',
      type: productData.type || '',
      name: productData.name || '',
      description: productData.description || '',
      category: productData.category || '',
      cost: productData.cost || '',
      selling: productData.selling || '',
      units: productData.units || '',
      amount_per_unit: productData.amount_per_unit || '',
      quantity: productData.quantity || '',
      low_stock: productData.lowStock || '',
      imageUrl: productData.imageUrl || '',
      variant: productData.variant || '',
      stockUnit: productData.stockUnit || '',
      stockQuantity: productData.stockQuantity || '',
    })
  }
  }, [productData])

  const systemTax = ['VAT', 'GRT', "CGT"];

  const [addedVariants, setAddedVariants] = useState([]);

  const addVariant = () => {
    if(variants.variantType === "" || variants.variantName === ""){
      toast.error("Empty Variant");
      return;
    }
    // sum up varaiant quantity to state
    let totalQuantity = 0;
    addedVariants.map((item) => {
      totalQuantity += item.variantQuantity;
    });

    setTotalVariantQuantity(totalQuantity);

    setAddedVariants([
      ...addedVariants,
      variants,
    ]);
    setVariants({
      variantType: "",
      variantName: "",
      variantCostPrice: 0,
      variantSellingPrice: 0,
      variantQuantity: 0,
    })
  };

  const createProductAction = async (stay) => {
    try {
      if (!isService && !isProduct) {
        toast.warning("Select Product type !");
      }

      if (formState.stockQuantity <= 0) {
        toast.warning("Select Quantity !");
      }
      setFormState({ ...formState });
      const body = {
        businessRef: user.businessRef,
        type: isService ? "service" : "product",
        name: formState.name,
        description: formState.description,
        category: formState.category,
        price: {
          cost: formState.cost || 0,
          selling: formState.selling || 0
        },
        stock: {
          units: formState.stockUnit || '',
          amount_per_unit: 0,
          quantity: formState.stockQuantity,
          low_stock: false
        },
        variant: addedVariants,
      }
      if (body.name === "" || body.description === "" || body.category === "") {
        toast.error("Please fill all the fields");
        return;
      }

      if (data.type === 'create') {
        const response = await createProduct(body, user._id, user.businessRef);

        if (response.status === 'ok') {
          toast.success("Product created successfully");
        }
      } else {
        const response = await updateProduct(body, productData._id);

        if (response.status === 'ok') {
          toast.success("Product updated successfully");
        }
      }
      setFormState({
        type: "",
        name: "",
        description: "",
        category: "",
        cost: 0,
        selling: 0,
        units: 0,
        amount_per_unit: 0,
        quantity: 0,
        low_stock: false,
        variant: "",
        stockUnit: "",
        stockQuantity: 0,
        imageUrl: "",
      });
      if (stay) {
        data.action();
      }
    } catch (err) {
      toast.error("Error, try again");
      setFormState({ ...formState });
    }
  };

  const updateForm = (e) => {
    const { value, name } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const updateVariant = (e) => {
    const { value, name } = e.target;
    setVariants({
      ...variants,
      [name]: value,
    });
  };
  
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const user = await getUser();
      if (user) {
        setUser(user);
        const response = await getAllProducts(user._id, user.businessRef);
        setProducts(response.data);
        setLoading(false);
      }
    }
    fetchData();

  }, []);

  const toggleProductStock = useCallback(() => {
    setTrackProductStock(prevCheck => !prevCheck);
  }, []);

  const toggleProductVariant = useCallback(() => {
    setProductVariant(prevCheck => !prevCheck);
  }, []);

  const toggleTax = useCallback(() => {
    setTax(prevCheck => !prevCheck);
  }, []);

  const toggleExpiryDate = useCallback(() => {
    setExpiryDate(prevCheck => !prevCheck);
  }, []);

  const toggleLowStock = useCallback(() => {
    setLowStock(prevCheck => !prevCheck);
  }, []);

  const toggleHideInPOS = useCallback(() => {
    setHideInPOS(prevCheck => !prevCheck);
  }, []);

  const toggleHideInShop = useCallback(() => {
    setHideInShop(prevCheck => !prevCheck);
  }, []);

  const toggleWareHouse = useCallback(() => {
    setWarehouse(prevCheck => !prevCheck);
  }, []);

  const toggleService = useCallback(() => {
    setService(prevCheck => !prevCheck);
  }, []);

  const toggleProduct = useCallback(() => {
    setProduct(prevCheck => !prevCheck);
  }, []);

  const handleChange = (selected) => {
    setFormState({
      ...formState,
      ['category']: selected.value,
    });
  };

  const categories = [...new Set(products.map(product => product.category))]

  const categoryOptions = categories.map(category => ({ value: category, label: category }));

  const uploadImage = (e) => {
    const { cdnUrl } = e;
    setFormState({
      ...formState,
      ['imageUrl']: cdnUrl,
    });
  };

  return (
    <div className="row m-0 ">
      <ToastContainer />
      <div className="col-md-12 col-xl-12 mx-auto input-container">
        <div className="card-body">
          <div className="col-12   mt-3">
            <label htmlFor="" className="mb-0" style={{ marginRight: "50px" }}>
              TYPE:
            </label>
            <label  style={{ marginRight: "10px" }}>
              {" "}
              Product
            </label>
            <input
              type="checkbox"
              style={{ marginRight: "50px" }}
              onClick={toggleProduct}
            />

            <label style={{ marginRight: "10px" }}>
              {" "}
              Service
            </label>
            <input
              type="checkbox"
              style={{ marginRight: "50px" }}
              onClick={toggleService}
            />
          </div>
          <div className="col-12 mt-3">
                <label htmlFor="" className="mb-0">
                  Select Branch:
                </label>
                <select
                  className="form-control custom-select input shadow-sm"
                  name=""
                  id=""
                >
                  <option>Branch 1</option>
                  <option>Branch 2</option>
                  <option>Branch 3</option>
                </select>
            </div>

          <div className="col-12  mt-3">
            <label htmlFor="" className="mb-0">
              Product Name:
            </label>
            <input
              type="text"
              className="form-control input shadow-sm"
              name="name"
              value={formState.name}
              onChange={updateForm}
              id=""
              aria-describedby="helpId"
              placeholder=""
            />
          </div>

          <div className="col-12 mt-2">
            <label htmlFor="" className="mb-0">
              Product Description:
            </label>
            <input
              type="text"
              className="form-control input shadow-sm"
              name="description"
              value={formState.description}
              onChange={updateForm}
              id=""
              aria-describedby="helpId"
              placeholder=""
            />
          </div>

          <div className="col-12 mt-2">
            <label htmlFor="" className="mb-0">
              Select Product Category:
            </label>

            <CreatableSelect
              onChange={handleChange}
              options={categoryOptions}
            />
          </div>

          <div className="col-12 mt-2">
            <div className="row m-0 p-0">
              <div className="col-5 mr-auto mt-2 p-0">
                <label htmlFor="" className="mb-0">
                  Cost Price:
                </label>
                <input
                  type="number"
                  className="form-control input shadow-sm"
                  name="cost"
                  value={formState.cost}
                  onChange={updateForm}
                  id=""
                  aria-describedby="helpId"
                  placeholder=""
                />
              </div>

              <div className="col-5 ml-auto mt-2 p-0">
                <label htmlFor="" className="mb-0 ">
                  Selling Price:
                </label>
                <input
                  type="number"
                  className="form-control input shadow-sm"
                  name="selling"
                  value={formState.selling}
                  onChange={updateForm}
                  id=""
                  aria-describedby="helpId"
                  placeholder=""
                />
              </div>
            </div>
          </div>

          <div className="col-12 mt-2">
            <label htmlFor="" className="mb-0">
              Bar Code:
            </label>
            <input
              type="text"
              className="form-control input shadow-sm"
              name=""
              id=""
              aria-describedby="helpId"
              placeholder=""
            />
          </div>

          <div className="col-12 mt-2">
            <div className="row m-0 p-0">
              <div className="col-12 mr-auto mt-2 p-0">
                <label htmlFor="" className="mb-0">
                  Product Image:
                </label>
                <Widget onChange={uploadImage} publicKey={UPLOAD_CARE_PUBLIC_KEY} />
              </div>

            </div>
          </div>
          <br></br>

          <div className="col-12">
            <span style={{ marginRight: "50px" }}>Track Product stock</span><br></br>
            <label className="label">
              <div className="toggle">
                <input className="toggle-state" onClick={toggleProductStock} type="checkbox" name="check" value="check" />
                <div className="toggle-inner">
                  <div className="indicator"></div>
                </div>
                <div className="active-bg"></div>
                </div>
              </label>
          </div>

          {trackProductStock ? 
          <div className="col-12 mt-2">
            <div className="row m-0 p-0">
              <div className="col-5 mr-auto mt-2 p-0">
                <label htmlFor="" className="mb-0">
                  Stock Unit:
                </label>

                <select
                  className="form-control custom-select input shadow-sm"
                  name="stockUnit"
                  onChange={updateForm}
                >
                  <option value={''}>Select Unit</option>
                  <option value={'Bottle'}>Bottle</option>
                  <option value={'Kg'}> Kilogram</option>
                  <option value={'pound'}> Pound</option>
                  <option value={'gallon'}> Gallon</option>
                </select>
              </div>

              <div className="col-5 ml-auto mt-2 p-0">
                <label htmlFor="" className="mb-0 ">
                  Stock Quantity:
                </label>
                <input
                  type="number"
                  className="form-control input shadow-sm"
                  name="stockQuantity"
                  value={formState.stockQuantity}
                  onChange={updateForm}
                  id=""
                  {...(isService ? { disabled: true } : {})}
                  aria-describedby="helpId"
                  placeholder=""
                />
              </div>
            </div>
          </div>
            : null}
          <br></br>

          <div className="col-12">
            <span style={{ marginRight: "50px" }}>Product Variants</span><br></br>
            <label className="label">
              <div className="toggle">
                <input className="toggle-state" onClick={toggleProductVariant} type="checkbox" name="check" value="check" />
                <div className="toggle-inner">
                  <div className="indicator"></div>
                </div>
                <div className="active-bg"></div>
              </div>
            </label>
          </div>

          {productVariant ? 
          <>
          <div className="col-12 mt-2">

            <label htmlFor="" className="mb-0">
              Product Variants:
            </label>
            {addedVariants.map((variant) => (
                <div key={variant.id} style={{backgroundColor: 'lightGrey', padding: '12px'}}>
                    <span style={{fontSize: "13px"}}> 
                      Name: {variant.variantName}, 
                      Price: {variant.variantCostPrice}, 
                      Qty: {variant.variantQuantity || 0}, 
                      Type: {variant.variantType}
                    </span>
                    <span>
                      <i  className="pointer mdi mdi-close" style={{ fontSize: '16px', color: 'rgb(237, 64, 20)' }}>

                      </i>
                    </span>
                </div>
              ))}
              <br></br>
              <div className="row m-0 p-0">
                <div className="col-5 mr-auto mt-2 p-0">
                  <label htmlFor="" className="mb-0">
                    Type:
                  </label>
                  <input
                    type="text"
                    className="form-control input shadow-sm"
                    name="variantType"
                    value={variants.variantType}
                    onChange={updateVariant}
                    aria-describedby="helpId" />
                </div>

                <div className="col-5 ml-auto mt-2 p-0">
                  <label htmlFor="" className="mb-0 ">
                    Name:
                  </label>
                  <input
                    type="text"
                    className="form-control input shadow-sm"
                    name="variantName"
                    value={variants.variantName}
                    onChange={updateVariant}
                    placeholder="" />
                </div>
              </div>
            </div><div className="col-12 mt-2">
                <div className="row m-0 p-0">
                  <div className="col-5 mr-auto mt-2 p-0">
                    <label htmlFor="" className="mb-0">
                      Cost Price:
                    </label>
                    <input
                      type="number"
                      className="form-control input shadow-sm"
                      name="variantCostPrice"
                      value={variants.variantCostPrice}
                      onChange={updateVariant}
                      placeholder="" />
                  </div>

                  <div className="col-5 ml-auto mt-2 p-0">
                    <label htmlFor="" className="mb-0 ">
                      Selling Price:
                    </label>
                    <input
                      type="number"
                      className="form-control input shadow-sm"
                      value={variants.variantSellingPrice}
                      name="variantSellingPrice"
                      id=""
                      onChange={updateVariant}
                      aria-describedby="helpId"
                      placeholder="" />
                  </div>
                </div>
              </div><div className="col-12 mt-2">
                <div className="row m-0 p-0">
                  <div className="col-5 mr-auto mt-2 p-0">
                    <label htmlFor="" className="mb-0">
                      Quantity:
                    </label>
                    <input
                      type="number"
                      className="form-control input shadow-sm"
                      value={variants.variantQuantity}
                      name='variantQuantity'
                      onChange={updateVariant}
                      {...(isService ? { disabled: true } : {})}
                      aria-describedby="helpId"
                      placeholder="" />
                  </div>

                  <div className="col-5 ml-auto mt-2 p-0">
                    <label htmlFor="" className="mb-0"></label>
                    <br></br>
                    <button
                      type="button"
                      onClick={() => addVariant()}
                      className="btn btn-primary rounded-x shadow "
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
              </>
          :null}
          <br></br>

          <div className="col-12">
            <span style={{ marginRight: "50px" }}>Apply Tax</span><br></br>
            <label className="label">
              <div className="toggle">
                <input className="toggle-state" onClick={toggleTax} type="checkbox" name="check" value="check" />
                <div className="toggle-inner">
                  <div className="indicator"></div>
                </div>
                <div className="active-bg"></div>
              </div>
            </label>
          </div>
          {tax ? 
          <div className="col-12 mt-2">
            <div className="row m-0 p-0">
              <div className="col-5 mr-auto mt-2 p-0">
                <label htmlFor="" className="mb-0">
                  Select Tax type:
                </label>
                <select
                  className="form-control custom-select input shadow-sm"
                  name=""
                  id=""
                > 
                <option>Select Tax</option>
                {systemTax.map((tax) => (
                  <option key={tax}>{tax}</option>
                ))}
                </select>
              </div>

              <div className="col-5 ml-auto mt-2 p-0">
                <label htmlFor="" className="mb-0 ">
                  Tax Rate:
                </label>
                <input
                  type="text"
                  className="form-control input shadow-sm"
                  name=""
                  id=""
                  aria-describedby="helpId"
                  placeholder=""
                />
              </div>
            </div>
          </div>
          : null}

          <br></br>

          <div className="col-12">
            <span style={{ marginRight: "50px" }}>Set Expiry Date</span><br></br>
            <label className="label">
              <div className="toggle">
                <input className="toggle-state" onClick={toggleExpiryDate} type="checkbox" name="check" value="check" />
                <div className="toggle-inner">
                  <div className="indicator"></div>
                </div>
                <div className="active-bg"></div>
              </div>
            </label>
          </div>

          {expiryDate ? 
          <div className="col-12 mt-2">
            <div className="row m-0 p-0">
              <div className="col-5 mr-auto mt-2 p-0">
                <label htmlFor="" className="mb-0">
                  Set Expiry Date:
                </label>
                <input
                  type="date"
                  className="form-control input shadow-sm"
                  name=""
                  id=""
                  aria-describedby="helpId"
                  placeholder=""
                />
              </div>

              <div className="col-5 ml-auto mt-2 p-0">
                <label htmlFor="" className="mb-0 ">
                  Start Rem. From:
                </label>
                <input
                  type="date"
                  className="form-control input shadow-sm"
                  name=""
                  id=""
                  aria-describedby="helpId"
                  placeholder=""
                />
              </div>
            </div>
          </div>
          : null}
          <br></br>

          <div className="col-12">
            <span style={{ marginRight: "50px" }}>low stock notification</span><br></br>
            <label className="label">
              <div className="toggle">
                <input className="toggle-state" onClick={toggleLowStock} type="checkbox" name="check" value="check" />
                <div className="toggle-inner">
                  <div className="indicator"></div>
                </div>
                <div className="active-bg"></div>
              </div>
            </label>
          </div>

          {lowStock ? 
          <div className="col-12 mt-2">
            <div className="row m-0 p-0">
              <div className="col-12 mr-auto mt-2 p-0">
                <label htmlFor="" className="mb-0">
                  Low Stock Level
                </label>
                <input
                  type="text"
                  className="form-control input shadow-sm"
                  name=""
                  id=""
                  aria-describedby="helpId"
                  placeholder=""
                />
              </div>

              <div className="col-5 ml-auto mt-2 p-0"></div>
            </div>
          </div>
          : null}

          <div className="col-12 mt-2">
            <div className="row m-0 p-0">
              <div className="col-12 mr-auto mt-2 p-0">
                <label style={{ marginRight: "50px" }} htmlFor="" className="mb-0">
                  Hide in POS{" "}
                </label><br></br>
                <label className="label">
                  <div className="toggle">
                    <input className="toggle-state" onClick={toggleHideInPOS} type="checkbox" name="hideInPOS" value="check" />
                    <div className="toggle-inner">
                      <div className="indicator"></div>
                    </div>
                    <div className="active-bg"></div>
                  </div>
                </label>
              </div>

              <div className="col-12 ml-auto mt-2 p-0">
                <label style={{ marginRight: "50px" }} htmlFor="" className="mb-0">
                  Hide in Shop{" "}
                </label><br></br>
                <label className="label">
                  <div className="toggle">
                    <input className="toggle-state" onClick={toggleHideInShop} type="checkbox" name="hideInShop" value="check" />
                    <div className="toggle-inner">
                      <div className="indicator"></div>
                    </div>
                    <div className="active-bg"></div>
                  </div>
                </label>
              </div>
            </div>
          </div>

          <div className="col-12 mt-2">
            <div className="row m-0 p-0">
              <div className="col-12 mr-auto mt-2 p-0">
                <label style={{ marginRight: "50px" }} htmlFor="" className="mb-0">
                  Ware House{" "}
                </label><br></br>
                <label className="label">
                  <div className="toggle">
                    <input className="toggle-state" type="checkbox" onClick={toggleWareHouse} name="warehouse" value="check" />
                    <div className="toggle-inner">
                      <div className="indicator"></div>
                    </div>
                    <div className="active-bg"></div>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <br></br>

          <div className="col-12 row mt-3">
            <div className="col-4">
            <button
              type="button"
              className="btn btn-default rounded-x  border rounded-x shadow"
              onClick={data.action}
            >
              Cancel
            </button>
            </div>
            <div className="col-4">
            <button
              type="button"
              className="btn btn-outline-primary rounded-x  rounded-x shadow"
              onClick={() => createProductAction({stay: true})}
            >
              Save & New
            </button>
            </div>
            <div className="col-4">
            {data.type === 'create' ?
            <button
              type="button"
              onClick={() => createProductAction()}
              className="btn btn-primary rounded-x shadow float-right"
            >
              Create
            </button>
            :
            <button
            type="button"
            onClick={() => createProductAction()}
            className="btn btn-primary rounded-x shadow float-right"
          >
            Update
          </button>
            }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateProduct;
