import request from 'umi-request';
import { API_URL, USER_TOKEN } from '../config/config';

export async function getAllRiders(userId, ref) {
  return request(`${API_URL}/api/rider/${userId}?businessRef=${ref}`, {
    method: 'get',
    headers: {
       'Content-Type': 'application/json',
       'Authorization': USER_TOKEN,
    },

  });
}


export async function getRider(userId, id) {
  return request(`${API_URL}/api/rider/?id=${id}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
    params: {
      userId: userId,
      id: id,
    },
  });
}

export async function createRider(body, userId) {
  return request(`${API_URL}/api/rider/${userId}`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
    body: JSON.stringify(body),
  });
}

export async function updateRider(body, userId, customerId) {
  return request(`${API_URL}/api/rider/${userId}?id=${customerId}`, {
    method: 'patch',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
    body: JSON.stringify(body),
  });
}


export async function deleteRider(userId, customerId, ref) {
  return request(`${API_URL}/api/rider/${userId}?id=${customerId}&ref=${ref}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
    params: {
      id: userId,
      ref: ref,
    },
  });
}
