import React  from 'react'
import '../../../App.scss';  
import { Link } from "react-router-dom";


const Products=()=> { 
   
  return ( 
    <div className='m-0 '> 
      <h6  className=" mb-4 col-12"><span>
              <i className="mdi mdi-account-multiple "></i>  
            </span> Purchases </h6>  

          <div className=" heads shadow-sm border h-75  p-3"> 
            <div className="  text-right  ">
              <Link to='/products/create-product' className="btn btn-primary mr-2 r4 text-white btn-sm p-2 px-4" > Add Purchases </Link>
            </div>
            <hr className='shadow mt-0 pt-0 mt-2'  />
            <div className="body">
             <div className="header text-right">
             <span className="mdi db-query mdi-magnify"></span>
              <input type="text" className='db-search pl-4' />
             </div>

            <div className="views mt-3 shadow-sm rounded-lg">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Date</th>
                <th scope="col">Details</th>
                <th scope="col">Supplier</th>
                <th scope="col">Total</th>
                <th scope="col">Created By</th>
                <th scope="col">Location</th>
                <th scope="col">Created At</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row"><input type={'checkbox'}></input></th>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
            </div>
              
            </div>
            
          </div>   
       
    </div>
  )
}

export default Products; 