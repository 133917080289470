import React, { useState, useEffect } from 'react'
import '../../../App.scss';
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import CreateSupplier from './CreateSupplier';
import { getUser } from '../../../config/common';
import { getAllSuppliers } from '../../../services/SupplierService';


const Suppliers = () => {
  const [createSuppliersModal, setSuppliersModal] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editSupplierModal, setEditSupplierModal] = useState(false);
  const [supplierData, setSupplierData] = useState([]);
  const closeSupplierModal = () => setSuppliersModal(false);
  const showCreateSupplierModal = () => setSuppliersModal(true);

  useEffect(() => {
    async function fetchData() {
        setLoading(true);
        const user = await getUser();
        if (user) {
            const userId = user._id;
            const response = await getAllSuppliers(userId, user.businessRef);
            setSuppliers(response.data);
            setUser(user);
            setLoading(false);

        }
    }
    fetchData();

}, []);

const showEditSupplierModal = (data) => {
  setEditSupplierModal(true);
  setSupplierData(data);
};
  return (
    <div className='m-0 '>
      <h6 className=" mb-4 col-12"><span>
        <i className="mdi mdi-account-multiple "></i>
      </span> Suppliers </h6>

      <div className=" heads shadow-sm border h-75  p-3">
        <div className="  text-right  ">
          <Link to='#' onClick={showCreateSupplierModal} className="btn btn-primary mr-2 r4 text-white btn-sm p-2 px-4" > Add Supplier </Link>
        </div>
        <hr className='shadow mt-0 pt-0 mt-2' />
        <div className="body">
          <div className="header text-right">
            <span className="mdi db-query mdi-magnify"></span>
            <input type="text" className='db-search pl-4' />
          </div>

          <div className="views mt-3 shadow-sm rounded-lg">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Name</th>
                  <th scope="col">Company</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Email</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
              {suppliers.map((supplier) => (
                <>
                <tr>
                  <th scope="row"><input type={'checkbox'}></input></th>
                  <td>{supplier.full_name}</td>
                  <td>{supplier.company}</td>
                  <td>{supplier.phone}</td>
                  <td>{supplier.email}</td>
                  <td>
                    <span onClick={() => showEditSupplierModal(supplier)} className="mdi mdi-pencil btn btn-primary text-white btn-md mx-1 r4" title="Edit"></span>
                    <span className="mdi mdi-keyboard-backspace btn btn-back text-white btn-md mx-1 r4"></span>
                    <span className="mdi mdi-delete btn btn-danger text-white btn-md mx-1 r4" title="Delete"></span>
                  </td>
                </tr>
                  </>
              ))}
              </tbody>
            </table>
          </div>

        </div>


      </div>
      <Modal
        show={createSuppliersModal}
        size="md"
        onHide={closeSupplierModal}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ width: "100%", borderRadius: "50px" }}
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <CreateSupplier type={'create'} />
        </Modal.Body>
      </Modal>

      <Modal
        show={editSupplierModal}
        size="md"
        onHide={() => setEditSupplierModal(false)}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ width: "100%", borderRadius: "50px" }}
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <CreateSupplier type={'update'} data={supplierData} />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Suppliers; 