import request from 'umi-request';
import { API_URL, USER_TOKEN } from '../config/config';

export async function getAllUsers() {
  return request(`${API_URL}/api/users`, {
    method: 'get',
    headers: {
       'Content-Type': 'application/json',
       'Authorization': USER_TOKEN,
    },
    // params: {
    //   id: userId,
    //   ref: ref,
    // },
  });
}

export async function getUser(userId, id) {
    return request(`${API_URL}/api/user/?id=${id}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': USER_TOKEN,
      },
      params: {
        userId: userId,
        id: id,
      },
    });
  }

export async function createUser(data) {
    const response = await fetch(`/api/user`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({user: data})
      })
    return await response.json();
}