import request from 'umi-request';
import { API_URL, USER_TOKEN } from '../config/config';

export async function getAllExpenses(userId, ref) {
  return request(`${API_URL}/api/expense`, {
    method: 'get',
    headers: {
       'Content-Type': 'application/json',
       'Authorization': USER_TOKEN,
    },
  });
}

export async function createExpense(body) {
  return request(`${API_URL}/api/expense/`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
    body: JSON.stringify(body),
  });
}

export async function updateExpense(body, customerId) {
  return request(`${API_URL}/api/expense?id=${customerId}`, {
    method: 'patch',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
    body: JSON.stringify(body),
  });
}


export async function deleteExpense(userId, customerId, ref) {
  return request(`${API_URL}/api/expense/${userId}?id=${customerId}&ref=${ref}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
    params: {
      id: userId,
      ref: ref,
    },
  });
}
