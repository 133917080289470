import React from "react";
import "../../../App.scss";
import "../../../assets/css/dashboard.css";


const PriceChecker = () => {
  return (
    <div className="m-0 ">
      <h6 className=" mb-4 col-12">
        <span>
          <i className="mdi mdi-check "></i>
        </span>{" "}  
        Check Price{" "}
      </h6>
      <div className="heads border h-75  p-3">
        <input type="text" placeholder="search amount" className="form-control input shadow-sm" /><br></br>
        <div className="row align-items-center">
        <button type="button" className="btn btn-primary rounded-x shadow float-right">Check Price</button>
          </div>
        </div>
    </div>
  );
};

export default PriceChecker;
