import React, { useState } from "react";
import { FormCheck } from "react-bootstrap";
import { Container, Row, Form, Col, Button } from "react-bootstrap";
import { loginUser } from "../../services/Auth";
import Logo from "../elements/Logo";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setUserSession } from '../../utils/common';
import LoadingBar from 'react-top-loading-bar'

const Login = () => {

  const [email, setUserName] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0)

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setProgress(10);
      setProgress(50);
      const response = await loginUser({
        email,
        password
      });
      setProgress(60);
      if ('token' in response.data) {
        setProgress(80);
        toast.success('Logged in successfully !')
        setUserSession(response.data.token, response.data);
        setProgress(100);
        window.location.href = "/dashboard";
      } else {
        setProgress(0);
        toast.error("Failed");
      }
    } catch (error) {
      setProgress(0);
      setLoading(false);
      toast.error("Wrong Password/Email. Please try again later.");
    }
  };

  return (
    <>
      <LoadingBar progress={progress} color='#f11946' />
      <Row>
        <Col lg={1} style={{ margin: "10px" }}>
          <Logo />
        </Col>
        <Col className="authForm" lg={5}>
          <Container>
            <ToastContainer />
            <Form noValidate onSubmit={handleSubmit} className="authFormInput">
              <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                <div style={{ float: "right" }}>
                  <h4>Login</h4>
                  <p>Welcome to Oneflare inventory Solution</p>
                </div>
                <Form.Label column sm="2">
                  Email
                </Form.Label>
                <Col sm="10">
                  <Form.Control name="email" onChange={e => setUserName(e.target.value)} placeholder="email@mail.com" />
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="2">
                  Password
                </Form.Label>
                <Col sm="10">
                  <Form.Control onChange={e => setPassword(e.target.value)} type="password" placeholder="Password" />
                </Col>
              </Form.Group>
              <div style={{ float: "right" }}>
                <a href="password/recovery" className="sub-menu-item">
                  Forget Password?
                </a>
                <div>
                  <Button
                    // type="submit"
                    style={{ float: "right", marginTop: "20px" }}
                    className="primary"
                    onClick={handleSubmit}
                  >
                    Login
                  </Button>
                </div>
              </div>
              <Form.Group controlId="formPlaintextPassword">
                <Col sm="10">
                  <FormCheck label="Remember me"></FormCheck>
                </Col>
              </Form.Group>
            </Form>
            <div className="authLink">
              <p>
                Don't have an account? <a href="/registration">Sign Up</a>
              </p>
            </div>
          </Container>
        </Col>
        <Col className="authBackground"></Col>
      </Row>
    </>
  );
};

export default Login;
