import React, { Component } from 'react';
import { Dropdown, Modal } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { getUser, removeUserSession } from '../../../config/common';
import Register from '../../Admin/POS/Register';

class Navbar extends Component {
  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
  toggleRightSidebar() {
    document.querySelector('.right-sidebar').classList.toggle('open');
  }
  toggler() {
    document.body.classList.toggle("marginToggle");
    document.body.classList.toggle('sidebar-icon-only')
  }
  // delcare state and use
  state = { document: {} };
  componentDidMount() { this.getUser(); }
  // assign data to state
  getUser = () => { this.setState({ user: getUser() }); }

  // get cart from local storage
  getCart = () => {
    const cart = JSON.parse(localStorage.getItem('cartItems')) || [];
    return cart;
  }

  selectCart = (e) => {
    const { value } = e.target;
    const cart = this.getCart();
    const cartItem = cart.find(order => order.cartId === value);
    this.props.parentCallback(cartItem);
    localStorage.setItem('cart', JSON.stringify(cartItem.cart));
  };

  navigateToDashboard() {
    this.props.history.push("/dashboard");
  }

  // declare setModal boolean
  setModal = (status) => {
    this.setState({ modal: status });
  }

  render() {
    this.toggler();
    const user = getUser();
    const logout = () => {
      removeUserSession();
    };

    return (

      <><nav className="navbar default-layout-navbar shadow-sm col-lg-12 col-12 p-0 py-2  fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <Link className="navbar-brand brand-logo" to="/"><img src={require('../../../assets/images/onFlare/Logo/logo.svg')} alt="logo" /></Link>
          <Link className="navbar-brand brand-logo-mini" to="/"><img src={require('../../../assets/images/onFlare/Logo/logo-mini.svg')} alt="logo" className="logo" /></Link>
        </div>

        {this.isPathActive("/pos") ? (
          <div className="navbar-menu-wrapper d-flex align-items-stretch">
            <ul className="navbar-nav navbar-nav-right">
              <li className="nav-item nav-link">
              </li>
              <li className="nav-item">
                <Dropdown alignRight>
                  <Dropdown.Toggle className="nav-link count-indicator">
                    <small className='text-primary'>Lekki Branch</small>
                    <i className='mdi mdi-map-marker-outline text-primary header-icon-right'></i>
                    <i onClick={() => this.navigateToDashboard()} className='mdi mdi-home-outline text-primary header-icon-right'></i>
                    <i onClick={() => this.setModal(true)} className='mdi mdi-monitor text-primary header-icon-right'></i>
                  </Dropdown.Toggle>
                </Dropdown>
              </li>

              <li className="nav-item nav-profile ">
                <span className="">
                  <Dropdown alignRight>
                    <Dropdown.Toggle className="nav-link pb-1">
                      <div className="nav-profile-img shadow">
                        <img src={require("../../../assets/images/faces/face1.jpg")} alt="user" />
                      </div>
                      <div className="nav-profile-text">
                        <p className="mb-1 text-black"><Trans>{user.firstName || 'Guest'}</Trans></p>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="navbar-dropdown">
                      <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()}>
                        <i className="mdi mdi-cached mr-2 text-success"></i>
                        <Trans>Activity Log</Trans>
                      </Dropdown.Item>
                      <Dropdown.Item href="" onClick={logout}>
                        <i className="mdi mdi-logout mr-2 text-primary"></i>
                        <Trans>Sign Out</Trans>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </span>
              </li>
            </ul>
          </div>
        ) : (
          <div></div>
        )}
        {this.isPathActive("/pos") ? (
          <div></div>
        ) : (
          <div className="navbar-menu-wrapper d-flex align-items-stretch">
            <ul className="navbar-nav navbar-nav-right">
              <li className='nav-item'>
                <Link to="/pos" className="btn btn-primary px-3  shadow-sm  align-self-center rounded-pill" type="button">
                  <small style={{ color: 'white' }} className="mdi mdi-cart-outline"> <span>Shop</span> </small>
                </Link>
              </li>

              <li className="nav-item nav-link">
                <small className='text-primary'>Lekki Branch</small><i className='mdi mdi-map-marker-outline text-primary header-icon-right'></i>
              </li>
              <li className="nav-item nav-profile ">
                <span className="">
                  <Dropdown alignRight>
                    <Dropdown.Toggle className="nav-link pb-1">
                      <div className="nav-profile-img shadow">
                        <img src={require("../../../assets/images/faces/face1.jpg")} alt="user" />
                      </div>

                      <div className="nav-profile-text">
                        <p className="mb-1 text-black"><Trans>{user.firstName || 'Guest'}</Trans></p>
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="navbar-dropdown">
                      <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()}>
                        <i className="mdi mdi-cached mr-2 text-success"></i>
                        <Trans>Activity Log</Trans>
                      </Dropdown.Item>
                      <Dropdown.Item href="" onClick={logout}>
                        <i className="mdi mdi-logout mr-2 text-primary"></i>
                        <Trans>Sign Out</Trans>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </span>
              </li>

            </ul>
          </div>
        )}
      </nav>
        <Modal
          show={this.state.modal}
          size="md"
          onHide={() => this.setModal(false)}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ width: "100%", borderRadius: "50px" }}
        >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <Register />
          </Modal.Body>
        </Modal></>
    );
  }
  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }
}
export default withRouter(Navbar);
