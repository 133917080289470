import request from 'umi-request';
import { API_URL, USER_TOKEN } from '../config/config';

export async function getAllRegister(userId, ref) {
  return request(`${API_URL}/api/register/${userId}?ref=${ref}`, {
    method: 'get',
    headers: {
       'Content-Type': 'application/json',
       'Authorization': USER_TOKEN,
    },
  });
}


export async function getCurrentRegister() {
  return request(`${API_URL}/api/register/current`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
  });
}

export async function closeCurrentRegister(body) {
    return request(`${API_URL}/api/register/close`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': USER_TOKEN,
      },
      body: JSON.stringify(body),
    });
  }

export async function createRegister(body) {
  return request(`${API_URL}/api/register/`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
    body: JSON.stringify(body),
  });
}

export async function updateRegister(body, userId, customerId) {
  return request(`${API_URL}/api/register/${userId}?id=${customerId}`, {
    method: 'patch',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
    body: JSON.stringify(body),
  });
}


export async function deleteRegister(userId, customerId, ref) {
  return request(`${API_URL}/api/register/${userId}?id=${customerId}&ref=${ref}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
    params: {
      id: userId,
      ref: ref,
    },
  });
}
