import React from "react";
import '../../../App.scss';
import Navbar from '../../../components/layout/admin/Navbar';
import POSSidebar from '../../../components/layout/admin/POSSidebar';
import ConfirmSales from '../../../components/Admin/POS/ConfirmSales';

const ConfirmSalesView = () => {

  return (
    <>
      <div className="container-scroller">
      <Navbar />
        <div className="page-body-wrapper">
        <POSSidebar/>
          <div className="main-panel">
            <div className="content-wrapper bg-white">
              <ConfirmSales />
              {/* <SettingsPanel /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ConfirmSalesView;
