import React from "react";
import '../../../App.scss';
import PriceChecker from "../../../components/Admin/PriceChecker/PriceChecker";
import Navbar from '../../../components/layout/admin/Navbar';
import Sidebar from '../../../components/layout/admin/Sidebar'; 

const ReceivedPurchasesView = () => {
  return (
    <>
      <div className="container-scroller">
      <Navbar />
        <div className="page-body-wrapper">
        <Sidebar />
          <div className="main-panel">
            <div className="content-wrapper bg-white" >
              <PriceChecker/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ReceivedPurchasesView;





