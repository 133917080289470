import React, { useState, useEffect, useCallback } from "react";
import "../../../App.scss";
import "../../../assets/css/dashboard.css";
import { getUser } from '../../../config/common';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { closeCurrentRegister, createRegister } from "../../../services/RegisterService";
const Register = () => {
  const [toggleForm, setToggleForm] = useState(false);
  const [cashAtHand, setCashAtHand] = useState(0);
  const [closingAmount, setClosingAmount] = useState(0);
  const [alreadyOpened, setAlreadyOpened] = useState(false);
  const [user, setUser] = useState([]);


  const updateOpeningCash = (e) => {
    const { value } = e.target;
    setOpenRegister({ ...openRegister, startingBalance: value });
  };

  const [openRegister, setOpenRegister] = useState({
    startingBalance: cashAtHand,
    dateOpened: '',
    timeOpened: '',
    dayStarted: false,
    user: {
      _id: '',
      name: '',
      email: '',
    },
  });

  useEffect(() => {
    async function fetchData() {
      const user = await getUser();
      if (user) {
        setUser(user);
      }
    }
    fetchData();
  }, []);


  const handleSetOpenRegister =  useCallback(async() => {
    try {

      const date = new Date();
      const dateOpened = date.toLocaleDateString();
      const timeOpened = date.toLocaleTimeString();
      const data = {
        startingBalance: openRegister?.startingBalance,
        dateOpened: dateOpened,
        timeOpened: timeOpened,
        dayStarted: true,
        user: {
          _id: user._id,
          name: user.full_name,
          email: user.email,
        },
      };
      const response = await createRegister({
        openingBalance: data.startingBalance,
      });
      if (response) {
        toast.success("Register opened successfully");
        setOpenRegister(data);

        setAlreadyOpened(true);
        localStorage.setItem('openRegisterData', JSON.stringify(data));
      }

    } catch (error) {
      toast.error("Error, try again");
    }

  }, [openRegister, user]);
 
  const closeRegister = async() => {
    const response = await closeCurrentRegister({
      closingBalance: closingAmount,
    });
    if (response) {
      toast.success("Register closed successfully");
      localStorage.removeItem('openRegisterData');
      setOpenRegister({});
      setAlreadyOpened(false);
    }
   
  };

  const getOpenRegister = JSON.parse(localStorage.getItem('openRegisterData'));

  useEffect(() => {
    if (getOpenRegister?.dayStarted) {
      setAlreadyOpened(true);
    }

  }, [getOpenRegister]);

  const updateClosingAmount = (e) => {
    const { value } = e.target;
    setClosingAmount(value);
  };

  const currencyFormatter = (number) => (number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));

  return (
    <div className="row m-0 ">
      <ToastContainer />
      <div className="col-md-12 col-xl-12 mx-auto input-container">
        <div className="card-body">
          {alreadyOpened && getOpenRegister?.dayStarted ? (
            <div data-v-17a8978f="" className="order register">
              <div className="ivu-list ivu-list-default ivu-list-horizontal ivu-list-bordered ivu-list-split">
                <div className="ivu-list-container">
                  <ul className="ivu-list-items">
                    <li className="ivu-list-item table-wrapper mt-0">
                      <table className="table">
                        <tr>
                          <td className="bold bg-gray" style={{ borderRadius: '5px 0px 0px' }}>Register</td>
                          <td className="bold bg-gray" style={{ borderRadius: '0px 5px 0px 0px' }}>Register 571</td>
                        </tr>
                        <tr>
                          <td>Opened at</td>
                          <td className="bold">{getOpenRegister.dateOpened}, {getOpenRegister.timeOpened}</td>
                        </tr>
                        <tr>
                          <td>Total Sales Amount</td>
                          <td className="bold" style={{ borderRadius: '0px 0px 5px' }}>
                            {currencyFormatter(getOpenRegister.startingBalance)}
                          </td>
                        </tr>
                      </table>
                      <div className="ivu-list-item-extra"></div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mt16 ivu-list ivu-list-default ivu-list-horizontal ivu-list-bordered ivu-list-split">

                <div className="ivu-list-container">
                  <ul className="ivu-list-items">
                    <li className="ivu-list-item table-wrapper mt-0">
                      <table className="table">
                        <tr>
                          <td>Cash in hand</td>
                          <td className="bold text-right">{currencyFormatter(getOpenRegister.startingBalance)}</td>
                        </tr>
                        <tr>
                          <td>Cash Amount</td>
                          <td className="bold text-right">0.00</td>
                        </tr>
                        <tr>
                          <td>Credit Card Amount</td>
                          <td className="bold text-right">0.00</td>
                        </tr>

                        <tr>
                          <td>Return Orders Amount</td>
                          <td className="bold text-right">0.00</td>
                        </tr>
                        <tr>
                          <td>Refund Amount</td>
                          <td className="bold text-right">0.00</td>
                        </tr>

                        <tr>
                          <td>Expenses</td>
                          <td className="bold text-right">0.00</td>
                        </tr>

                        <tr>
                          <td>Purchases</td>
                          <td className="bold text-right">0.00</td>
                        </tr>
                      </table>

                      <div className="ivu-list-item-extra"></div>
                    </li>
                  </ul>
                </div>

              </div>
              {!toggleForm && (
                <button onClick={() => setToggleForm(true)} type="button" className="mt16 np ivu-btn ivu-btn-dashed ivu-btn-long"><span>Close Register</span></button>
              )}
              {toggleForm && (
                <div className="mt16">
                  <form  className="ivu-form ivu-form-label-right">
                    <div className="ivu-form-item">
                      <label className="ivu-form-item-label" >Total Cash</label>
                      <div className="ivu-form-item-content">
                        <div className="ivu-row">
                          <div className="ivu-col ivu-col-span-14">
                            <div className="ivu-form-item ivu-form-item-required">

                              <div className="ivu-form-item-content">
                                <input type="text"
                                  className="form-control ivu-input-number-input input shadow-sm" name="closingAmount" value={closingAmount}
                                  onChange={updateClosingAmount} id="" aria-describedby="helpId" placeholder="" />

                              </div>
                            </div>
                          </div>
                          <div className="ivu-col ivu-col-span-1" style={{ textAlign: "center" }}>&nbsp;</div>
                          <div className="ivu-col ivu-col-span-9"><code>=</code> <strong>
                            {currencyFormatter(getOpenRegister.startingBalance - closingAmount)}
                          </strong>
                          </div>
                        </div>

                      </div>
                    </div>

                    <div className="ivu-form-item">
                      <label className="ivu-form-item-label" >Comment</label>
                      <div className="ivu-form-item-content">
                        <div className="ivu-input-wrapper ivu-input-wrapper-default ivu-input-type-textarea">
                          <textarea wrap="soft" placeholder="Comment" rows="2" className="ivu-input" style={{ height: '52px', minHeight: '52px', maxHeight: '115px', overflowy: 'hidden' }}></textarea>

                        </div>

                      </div>
                    </div>
                    <div className="mb0 ivu-form-item">

                      <div className="ivu-form-item-content">
                        <button onClick={() => closeRegister()} type="button" className="ivu-btn ivu-btn-primary">
                          <span>Submit</span>
                        </button>
                        <button onClick={() => setToggleForm(false)} type="button" className="ivu-btn ivu-btn-default" style={{ marginLeft: '8px' }}>
                          <span>Close Form</span>
                        </button>

                      </div>
                    </div>
                  </form>
                </div>
              )}
            </div>
          ) : (
            <div className="">
              <form autocomplete="off" className="ivu-form">
                <div className="ivu-form-item">
                  <label className="ivu-form-item-label" >Total Cash at Hand</label>
                  <input type="text" className="form-control input shadow-sm" name="cashAtHand" value={openRegister?.startingBalance} onChange={updateOpeningCash} id="" aria-describedby="helpId" placeholder="" />
                  <div className="ivu-form-item-content"><br />
                    <button onClick={() => handleSetOpenRegister()} type="button" className="ivu-btn ivu-btn-lg ivu-btn-primary">
                      <span>Submit Cash at hand</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Register;
