import request from 'umi-request';
import { API_URL, USER_TOKEN } from '../config/config';

export async function getAllProducts() {
  return request(`${API_URL}/api/products`, {
    method: 'get',
    headers: {
       'Content-Type': 'application/json',
       'Authorization': USER_TOKEN,
    },
    // params: {
    //   id: userId,
    //   ref: ref,
    // },
  });
}

export async function createProduct(body, userId, ref) {
  return request(`${API_URL}/api/products`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
    body: JSON.stringify(body),
  });
}

export async function updateProduct(body, userId, productId) {
  return request(`${API_URL}/api/products/${userId}?id=${productId}`, {
    method: 'patch',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
    body: JSON.stringify(body),
  });
}


export async function deleteProduct(productId) {
  return request(`${API_URL}/api/products/${productId}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
  });
}
