import React from "react";
import '../../../App.scss';
import Navbar from '../../../components/layout/admin/Navbar';
import Sidebar from '../../../components/layout/admin/Sidebar';
import MainPurchase from '../../../components/Admin/Purchase/MainPurchase';

const MainPurchaseView = () => {

  return (
    <>
      <div className="container-scroller">
      <Navbar />
        <div className="page-body-wrapper">
        <Sidebar />
          <div className="main-panel">
            <div className="content-wrapper bg-white">
              <MainPurchase />
              {/* <SettingsPanel /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MainPurchaseView;
