import React, {useState} from "react";
import "../../../App.scss";
import "../../../assets/css/dashboard.css";
import { Button, Modal } from "react-bootstrap";


const ReceivedPurchases = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const showReceivePoModal = () => setShow(true);
  return (
    <div className="m-0 ">
      <h6 className=" mb-4 col-12">
        <span>
          <i className="mdi mdi-cart "></i>
        </span>{" "}
        Received Purchases{" "}
      </h6>

      <div className=" heads shadow-sm border h-75  p-3">
        <div className="row align-items-center">
          <div className="col-md-4 form-group form-inline">
            <label htmlFor="name" className="mb-1 header-form-labels">
              #Date:{" "}
            </label>
            <input
              type="date"
              className="form-control input shadow-sm"
              name="name"
              id="name"
              aria-describedby="helpId"
              placeholder=""
            />
          </div>

          <div
            style={{ float: "right" }}
            className="col-md-4 form-group form-inline"
          >
            <label htmlFor="name" className="mb-0 header-form-labels">
              Product
            </label>
            <select
              className="form-control header-select input shadow-sm"
              name="supplier"
              id=""
            >
              <option>Select Product</option>
              <option>1</option>
              <option>2</option>
              <option>3</option>
            </select>
          </div>

          <div
            style={{ float: "right" }}
            className="col-md-4 form-group form-inline"
          >
            <label htmlFor="name" className="mb-0 header-form-labels">
              Supplier{" "}
            </label>
            <select
              className="form-control header-select input shadow-sm"
              name="supplier"
              id=""
            >
              <option>Select Supplier</option>
              <option>1</option>
              <option>2</option>
              <option>3</option>
            </select>
          </div>
          </div>

        <hr className="shadow mt-0 pt-0 mt-2" />
        <div className="body">
          <div className="header text-right">
            <span className="mdi db-query mdi-magnify"></span>
            <input type="text" className="db-search pl-4" />
          </div>

          <div className="views mt-3 shadow-sm rounded-lg">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Product Name</th>
                  <th scope="col">Variant</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Unit Cost </th>
                  <th scope="col">Total Cost</th>
                  <th scope="col">Available Qty</th>
                  <th scope="col">Created By</th>
                  <th scope="col">Created At</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    <input type={"checkbox"}></input>
                  </th>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                  <td>@mdo</td>
                  <td>@mdo</td>
                  <td>@mdo</td>
                  <td>@mdo</td>
                  <td>@mdo</td>
                  <td>
                    <span className="mdi mdi-pencil btn btn-primary text-white btn-md mx-1 r4" title="Edit"></span>
                    <span className="mdi mdi-keyboard-backspace btn btn-warning text-white btn-md mx-1 r4" title="Back"></span>
                    <span className="mdi mdi-delete btn btn-danger text-white btn-md mx-1 r4" title="Delete"></span>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <input type={"checkbox"}></input>
                  </th>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                  <td>@mdo</td>
                  <td>@mdo</td>
                  <td>@mdo</td>
                  <td>@mdo</td>
                  <td>@mdo</td>
                  <td>
                    <span className="mdi mdi-pencil btn btn-primary text-white btn-md mx-1 r4" title="Edit"></span>
                    <span className="mdi mdi-keyboard-backspace btn btn-warning text-white btn-md mx-1 r4" title="Back"></span>
                    <span className="mdi mdi-delete btn btn-danger text-white btn-md mx-1 r4" title="Delete"></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="row">
          <div className="col-md-4 form-group form-inline">
          <button onClick={showReceivePoModal} className="btn btn-outline-primary btn-md">Receive From PO</button>
            
          </div>
          <div className="col-md-3 form-group form-inline">
          <label htmlFor="name" className="mb-0 header-form-labels">
              Total{" "}
            </label>
            <input
              type="text"
              readOnly
              value={1000}
              className="form-control input shadow-sm"
              name="name"
              id="name"
              aria-describedby="helpId"
              placeholder=""
            />
          </div>
          <div className="col-md-5 table-footer-button">
            <button className="btn btn-default btn-md">Cancel</button>
            <button className="btn btn-outline-primary btn-md">Save & Print</button>
            <button className="btn btn-primary btn-md">Save</button>
          </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        size={'lg'}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ width: "100%", borderRadius: "50px" }}
      >
        <Modal.Body>
        <div className="col-12  mt-3">
          <h4> Select Purchase Order</h4>
          <div
            className="form-group form-inline"
          >
            <label htmlFor="name" className="mb-0 header-form-labels">
            Select the purchase order you want to receive against:
            </label>
            <select
              className="form-control header-select input shadow-sm"
              name="supplier"
              id=""
            >
              <option>Select Product</option>
              <option>1</option>
              <option>2</option>
              <option>3</option>
            </select>
          </div>

        <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Select</th>
                <th scope="col">#PO</th>
                <th scope="col">Order Date</th>
                <th scope="col">Vendor</th>
                <th scope="col">Status</th>
                <th scope="col">Qty</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><input type={'checkbox'}/></td>
                <td>Otto</td>
                <td>@mdo</td>
                <td>@mdo</td>
                <td>@mdo</td>
                <td>@mdo</td>
              </tr>
            </tbody>
          </table>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary">Add</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ReceivedPurchases;
