import React, {useState} from "react";
import { useEffect } from "react";

const ProductDetails = (data) => {
    const [productDetails, setProductDetails] = useState(data.product);
    console.log(data.product)
    useEffect(() => {
        setProductDetails(data.product);
    }, [data.product]);

    return (
        <div className="m-0">
            <h6 className=" mb-4 col-12">
                <span>  <i className="mdi mdi-account-multiple "></i> </span>
                Product Details
            </h6>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <ul style={{lineHeight: '30px'}}>
                                <li> <strong>Name:</strong> {productDetails.name}</li>
                                <li> <strong>Description:</strong> {productDetails.description}</li>
                                <li> <strong>Selling Price:</strong> {productDetails.price.selling}</li>
                                <li> <strong>Cost Price:</strong> {productDetails.price.cost}</li>
                                <li> <strong>Quantity:</strong> {productDetails.stock.quantity}</li>
                                <li> <strong>Category:</strong> {productDetails.category}</li>
                                <li> <strong>Sub Category:</strong> {productDetails.subCategory}</li>
                                <li> <strong>Brand:</strong> {productDetails.brand}</li>
                                <li> <strong>Image:</strong> {productDetails.image}</li>
                                <li> <strong>Created At:</strong> {new Date(productDetails.createdAt).toLocaleDateString()}</li>
                                <li> <strong>Updated At:</strong> {new Date(productDetails.updatedAt).toLocaleDateString()}</li>
                                <li><strong>Variants</strong> <ul>
                                {productDetails.variant.map((variant, index) => {
                                    return (
                                        <li key={index}>
                                            <strong>ID:</strong> {variant._id} <br/>
                                            <strong>Variant Name:</strong> {variant.name} <br/>
                                            <strong>Variant Cost:</strong> {variant.cost} <br/>
                                            <strong>Variant Selling:</strong> {variant.selling} <br/>
                                            <strong >Variant Quantity:</strong> {variant.quantity} <br/>
                                        </li>
                                    )
                                })}
                                </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ProductDetails;