import React, { useState, useEffect } from 'react'
import '../../../App.scss';
import { Country } from 'country-state-city';
import { createExpense, updateExpense } from '../../../services/ExpenseService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUser } from '../../../config/common';
import { useMemo } from 'react';

const CreatExpense = (data) => {
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({
      date: "",
      title: "",
      amount: 0,
      status: "pending",
      category: "",
      details: "",
      recurring: true
  });
  const expenseData = data.data || {};

  useMemo(() => {
    if (expenseData._id) {
      setFormState({
        ...formState,
        date: expenseData.date,
        title: expenseData.title || '',
        amount: expenseData.amount || '',
        status: expenseData.status || '',
        category: expenseData.category || '',
        details: expenseData.details || '',
        recurring: expenseData.recurring || true,
      })
    }
  }, [expenseData])



  const updateExpenseAction = async () => {
    try {
      setFormState({ ...formState });
      const body = {
        date: formState.date,
        title: formState.title,
        amount: formState.amount,
        status: formState.status,
        category: formState.category,
        details: formState.details,
        recurring: true,
      }

      if (data.type === 'create') {
        const response = await createExpense(body);

        if (response.status === 'ok') {
          toast.success("Expense created successfully");
        }
      } else {
        const response = await updateExpense(body, expenseData._id);

        if (response.status === 'ok') {
          toast.success("Expense updated successfully");
        }
      }
      setFormState({
        date: "",
        title: "",
        amount: 0,
        status: "pending",
        category: "",
        details: "",
        recurring: true
      });
    } catch (err) {
      toast.error("Error, try again");
      setFormState({ ...formState });
    }
  };

  const updateForm = (e) => {
    const { value, name } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const user = await getUser();
      if (user) {
        setUser(user);
        setLoading(false);
      }
    }
    fetchData();

  }, []);

  return (
    <div className='row m-0 '>
      <ToastContainer />
      <div className="col-md-12 col-xl-12 mx-auto input-container">
        <div className=' card-body'>
          <h6 className=" mb-4 col-12"><span>
            <i className="mdi mdi-account-plus"></i>
          </span> {data.type === 'create' ? 'Create' : 'Update'} Expense </h6>

          <div className="col-12  mt-3">
            <label htmlFor="" className='mb-0'>Title:</label>
            <input type="text"
              className="form-control input shadow-sm" name="title" value={formState?.title}
              onChange={updateForm} id="" aria-describedby="helpId" placeholder="" />
          </div>


          <div className="col-12 mt-2">
            <label htmlFor="" className='mb-0'>Amount:</label>
            <input type="text"
              className="form-control input shadow-sm" name="amount" value={formState?.amount}
              onChange={updateForm} id="" aria-describedby="helpId" placeholder="" />
          </div>

          <div className="col-12 mt-2">
            <label htmlFor="" className='mb-0'>Category:</label>
            <input type="text"
              className="form-control input shadow-sm" name="category" value={formState?.category}
              onChange={updateForm} id="" aria-describedby="helpId" placeholder="" />
          </div>

          <div className="col-12 mt-2">
            <label htmlFor="" className='mb-0'>Details:</label>
            <input type="text"
              className="form-control input shadow-sm" name="details" value={formState?.details}
              onChange={updateForm} id="" aria-describedby="helpId" placeholder="" />
          </div>

          <div className="col-12 mt-2">
            <label htmlFor="" className='mb-0'>Recurring:</label>
            <input type="text"
              className="form-control input shadow-sm" name="recurring" value={formState.recurring}
              onChange={updateForm} id="" aria-describedby="helpId" placeholder="" />
          </div>

          <div className="col-12 mt-2">
            <label htmlFor="" className='mb-0'>Recurring:</label>
            <input type="date"
              className="form-control input shadow-sm" name="date" value={formState.date}
              onChange={updateForm} id="" aria-describedby="helpId" placeholder="" />
          </div>
          
          <div className="col-12 mt-3">
            <button type="button" className="btn rounded-circle  border rounded-x shadow"><i className="mdi mdi-keyboard-backspace" aria-hidden="true"></i></button>
            {data.type === 'create' ?
              <button type="button" onClick={() => updateExpenseAction()} className="btn btn-primary rounded-x shadow float-right">Create<i className="mdi mdi-plus" aria-hidden="true"></i></button>
              :
              <button type="button" onClick={() => updateExpenseAction()} className="btn btn-primary rounded-x shadow float-right">Update<i className="mdi mdi-plus" aria-hidden="true"></i></button>
            }
          </div>

        </div>

      </div>
    </div>
  )
}

export default CreatExpense;  