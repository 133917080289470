import React, { useRef } from "react";
import { Switch } from "react-router-dom";
import AppRoute from "./utils/AppRoute";
import ScrollReveal from "./utils/ScrollReveal";
import { BrowserRouter as Router } from 'react-router-dom';

// Layouts
import LayoutDefault from "./layouts/LayoutDefault";
import AdminLayout from "./layouts/AdminLayout";

// Views 
// Auth
import Login from "./views/Auth/Login";
import PasswordRecovery from "./views/Auth/PasswordRecovery";
import NotFound from "./views/NotFound";
import Registration from "./views/Auth/Registration";
import Dashboard from "./views/Admin/Dashboard";
import POS from "./views/Admin/POS/POS";
import ConfirmSales from "./views/Admin/POS/ConfirmSales";
import Customers from "./views/Admin/Customers/Customers";
import Expenses from "./views/Admin/Expenses/Expenses";

//############# || ROUTES || ##############\\

// Users
import Users from "./views/Admin/Users/Users";
// Products
import Products from "./views/Admin/Products/Products";

// Rider
import Riders from "./views/Admin/Riders/Riders";

// Sales
import MainSales from "./views/Admin/Sales/MainSales";
import Sales from "./views/Admin/Sales/Sales";

// Purchase
import MainPurchase from "./views/Admin/Purchase/MainPurchase";
import Purchases from "./views/Admin/Purchase/Purchase";

// Suppliers
import Suppliers from "./views/Admin/Suppliers/Suppliers";

// Return Orders
import CreateReturnOrder from "./views/Admin/ReturnOrders/CreateReturnOrder";
import ReturnOrders from "./views/Admin/ReturnOrders/ReturnOrders";

// Purchased Orders
import PurchasedOrders from "./views/Admin/PurchasedOrders/PurchasedOrders";

// Received Purchases
import ReceivedPurchases from "./views/Admin/ReceivedPurchases/ReceivedPurchases";

//Price Checker
import PriceChecker from "./views/Admin/PriceChecker/PriceChecker";
import { getUser } from "./config/common";
// import { getUser, setUserSession } from "./utils/common";
import { handleLogout } from "./utils/logout";
import ErrorBoundary from "antd/lib/alert/ErrorBoundary";


/* eslint-disable */

const App = () => {
  // eslint-disable-next-line
  const childRef = useRef();
  if ((!getUser() && window.location.pathname !== ("/login")) && ( window.location.pathname !== ("/registration"))) {
    handleLogout();
  }
  return (
    <>
    <ErrorBoundary>
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Router>
        <Switch>
          <AppRoute exact path="/" component={Login} layout={LayoutDefault} />
          <AppRoute
            exact
            path="/login"
            component={Login}
            layout={LayoutDefault}
          />
           <AppRoute
            exact
            path="/password/recovery"
            component={PasswordRecovery}
            layout={LayoutDefault}
          />
           <AppRoute
            exact
            path="/registration"
            component={Registration}
            layout={LayoutDefault}
          />
          <AppRoute
            exact
            path="/dashboard"
            component={Dashboard}
            layout={AdminLayout}
          />

          <AppRoute
            exact
            path="/pos"
            component={POS}
            layout={AdminLayout}
          />

           <AppRoute
            exact
            path="/pos/confirm/sales"
            component={ConfirmSales}
            layout={AdminLayout}
          />
           <AppRoute
            exact
            path="/customers"
            component={Customers}
            layout={AdminLayout}
          />

          <AppRoute
            exact
            path="/expenses"
            component={Expenses}
            layout={AdminLayout}
          />

        <AppRoute
            exact
            path="/users"
            component= {Users }
            layout={AdminLayout}
          />


        <AppRoute
            exact
            path="/products"
            component= {Products }
            layout={AdminLayout}
          />


          <AppRoute
            exact
            path="/riders"
            component= {Riders }
            layout={AdminLayout}
          /> 

          <AppRoute
            exact
            path="/sales/main-sales"
            component= {MainSales }
            layout={AdminLayout}
          />  

          <AppRoute
            exact
            path="/sales/list-sales"
            component= {Sales }
            layout={AdminLayout}
          />  

        <AppRoute
            exact
            path="/purchase/main-purchase"
            component= {MainPurchase }
            layout={AdminLayout}
          />  

          <AppRoute
            exact
            path="/purchase/list-purchase"
            component= {Purchases }
            layout={AdminLayout}
          />  

          <AppRoute
            exact
            path="/supplier"
            component= {Suppliers }
            layout={AdminLayout}
          />    

          <AppRoute
            exact
            path="/return-orders/create-return-order"
            component= {CreateReturnOrder }
            layout={AdminLayout}
          />

          <AppRoute
            exact
            path="/return-orders"
            component= {ReturnOrders }
            layout={AdminLayout}
          /> 

          <AppRoute
            exact
            path="/purchase-orders"
            component= {PurchasedOrders }
            layout={AdminLayout}
          />    
          
          <AppRoute
            exact
            path="/received-purchases"
            component= {ReceivedPurchases }
            layout={AdminLayout}
          />  

          <AppRoute
            exact
            path="/price-checker"
            component= {PriceChecker }
            layout={AdminLayout}
          />  

          <AppRoute component={NotFound} layout={LayoutDefault} />
        </Switch>
        </Router>
      )}
    />
    </ErrorBoundary> 
    </>
  );
};

export default App;
