import React, {useState, useEffect}  from 'react'
import '../../../App.scss';  
import { Link } from "react-router-dom";
import {  Modal } from "react-bootstrap";
import CreateRider from './CreateRider';
// import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUser } from '../../../config/common';
import { getAllRiders } from '../../../services/RiderService';

const Riders=()=> { 
  const [show, setShow] = useState(false);
  const [createRidersModal, setRidersModal] = useState(false);
  const [riders, setRiders] = useState([]);
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const showFundCustomerModal = () => setShow(true);

  const closeProductModal = () => setRidersModal(false);
  const showCreateRidersModal = () => setRidersModal(true);
  console.log(riders)

  useEffect(() => {
    async function fetchData() {
        setLoading(true);
        const user = await getUser();
        if (user) {
            const userId = user._id;
            const response = await getAllRiders(userId, user.businessRef);
            setRiders(response.data);
            setUser(user);
            setLoading(false);

        }
    }
    fetchData();

}, []);
  return ( 
    <div className='m-0 '> 
      <h6  className=" mb-4 col-12"><span>
              <i className="mdi mdi-account-multiple "></i>  
            </span> Riders </h6>  

          <div className=" heads shadow-sm border h-75  p-3"> 
            <div className="  text-right  ">
              <Link to='#' onClick={showCreateRidersModal} className="btn btn-primary mr-2 r4 text-white btn-sm p-2 px-4" > Add Rider </Link>
            </div>
            <hr className='shadow mt-0 pt-0 mt-2'  />
            <div className="body">
             <div className="header text-right">
             <span className="mdi db-query mdi-magnify"></span>
              <input type="text" className='db-search pl-4' />
             </div>

            <div className="views mt-3 shadow-sm table-responsive rounded-lg">
          <table className="table table-striped ">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Name</th>
                <th scope="col">Vehicle</th>
                <th scope="col">Phone</th>
                <th scope="col">Company</th>
                <th scope="col">Address</th>
                <th scope="col">Plate Number</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
            {riders.map((rider) => (
              <tr key={rider.id}>
                <th scope="row"><input type={'checkbox'}></input></th>
                <td>{rider.full_name}</td>
                <td>{rider.vehicle}</td>
                <td>{rider.phone}</td>
                <td>{rider.company}</td>
                <td>{rider.location.address}</td>
                <td>{rider.plate_number}</td>
                <td>
                  <span className="mdi mdi-pencil btn btn-primary text-white btn-md mx-1 r4" title="Edit"></span>
                  <span  onClick={showFundCustomerModal} className="mdi mdi-clock btn btn-warning text-white btn-md mx-1 r4" title='Fund'></span>
                  <span className="mdi mdi-delete btn btn-danger text-white btn-md mx-1 r4" title="Delete"></span>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
            </div>
              
            </div>
            
          </div>   
          <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        style={{ width: "100%", borderRadius: "50px" }}
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
        <div className="col-12  mt-3">
        <table className="table table-striped table-responsive">
            <thead>
              <tr>
                <th scope="col">Location</th>
                <th scope="col">Time</th>
                <th scope="col">Date</th>
                <th scope="col">Clients Name</th>
                <th scope="col">Phone Number</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
                <td>@mdo</td>
                <td>@mdo</td>
              </tr>
            </tbody>
          </table>
          </div>

        </Modal.Body>
      </Modal>


      <Modal
        show={createRidersModal}
        size="md"
        onHide={closeProductModal}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ width: "100%", borderRadius: "50px" }}
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
        <CreateRider/>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Riders; 