import '../../../App.scss';
import "../../../assets/css/dashboard.css";
import "../../../assets/css/pos.css";
import { Modal } from "react-bootstrap";
import PrintReceipt from "../Receipt/Receipt";
import React, { useState, useEffect, useMemo } from "react";
import { getAllProducts } from "../../../services/ProductService";
import { getUser, removeUserSession } from "../../../config/common";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import { createCustomerSale, createWalkinSale } from "../../../services/SaleService";
import { getAllCustomers } from "../../../services/CustomerService";
import { useFocus } from '@react-aria/interactions'
import { getAllRiders } from "../../../services/RiderService";
import { useCallback } from "react";
import { Dropdown } from 'react-bootstrap';
import { Trans } from 'react-i18next';

import Register from '../../Admin/POS/Register';

const POS = () => {
  const [show, setShowPrintModal] = useState(false);
  const [clearedPayment, setClearedPayment] = useState(false);
  const [registerModal, setRegisterModal] = useState(false);
  const handleClose = () => setShowPrintModal(false);
  const [toggleSplitPayment, setToggleSplitPayment] = useState(false);
  const [applyDiscount, setToggleApplyDiscount] = useState(false);
  const [showRiderModal, setRiderModal] = useState(false);
  const [displayType, setDisplayType] = useState("");
  const [products, setProducts] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [riders, setRiders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState([]);
  const [splitAmount, setTotalAmount] = useState(0);
  const [myCart, setCart] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [customer, setCustomer] = useState({});
  const [searchProduct, setSearchProduct] = useState('');
  const [selectedCategory, setFilteredProduct] = useState('');
  const [currentOrder, setCurrentOrder] = useState('');
  const [currentCategory, setCurrentCategory] = useState('');
  const [selectedRider, setSelectedRider] = useState({});
  const [addedPaymentType, setAddedPaymentType] = useState([]);
  const [appliedDiscount, setApplyDiscount] = useState({});

  const [formState, setFormState] = useState({
    product: "",
    category: "",
  });

  const [paymentState, setPaymentState] = useState({
    id: Math.floor(Math.random() * 100000),
    type: "",
    amount: 0,
    details: ""
  });

  const [discount, setDiscount] = useState({
    type: "",
    value: 0,
  });

  console.log('appliedDiscount', appliedDiscount)
  const queryString = window.location.search;

  const queryStringWithoutQuestionMark = queryString.replace("?=", "");

  useEffect(() => {
    queryStringWithoutQuestionMark !== "" &&
      setFilteredProduct(queryStringWithoutQuestionMark);
    setTimeout(() => {
      window.history.pushState("", "", window.location.pathname);
    }, 2000);
  }
    , [queryStringWithoutQuestionMark, products]);

  const paymentTypes = ["transfer", "card", "account", "loyalty",];

  const getCart = () => {
    const cart = JSON.parse(localStorage.getItem('cartItems')) || [];
    return cart;
  }
  const savedCarts = getCart();

  useEffect(() => {
    if (localStorage.getItem("cart") === null) {
      localStorage.setItem("cart", JSON.stringify([]));
    }
  }, []);

  useFocus({
    focusTrapOptions: {
      clickOutsideDeactivates: true,
    },
    onFocus: () => {
      console.log('Focused');
    }
  });

  const logout = () => {
    removeUserSession();
  };

  const handleDeleteCart = (orderId) => {
    const cartItems = JSON.parse(localStorage.getItem("cartItems"));
    if (cartItems) {
      const newCart = cartItems.filter(item => item.cartId !== orderId);
      localStorage.setItem("cartItems", JSON.stringify(newCart));
      localStorage.setItem("cart", JSON.stringify([]));
    }
    localStorage.setItem("cart", JSON.stringify([]));
    setCart([]);
  };

  useEffect(() => {
    JSON.parse(localStorage.getItem("cart")).map((item) => {
      setTotalQuantity(totalQuantity + item.quantity);
    });
  }, []);

  useEffect(() => {
    const cart = JSON.parse(localStorage.getItem("cart"));
    setCart(cart);
  }, []);


  const cart = useMemo(() => {
    return myCart.map((item) => {
      return {
        ...item,
        id: item?.cart?.product?._id,
        name: item?.name,
        cart: item?.cart,
        price: item?.cart?.product?.price?.selling,
        cost: item?.cart?.product?.price?.cost,
        productName: item?.cart?.product?.name,
        quantity: item?.quantity,
        total: item?.quantity * item?.cart?.product?.price?.selling,
      };
    });
  }, [myCart]);


  const showPrintReceiptModal = (type) => {
    setDisplayType(type);
    setShowPrintModal(true);
  };

  const payNow = async () => {
    try {
      const customerData = {
        id: customer?._id,
        name: customer?.full_name,
        phone: customer?.phone,
        email: customer?.email,
      }
      const defaultPaymentMethod = [
        {
          type: "cash",
          details: "the details",
          amount: addedPaymentType.length > 0 ? splitAmount : cart.reduce((a, c) => a + c.price * c.quantity, 0),
        }
      ];

      addedPaymentType.push(...defaultPaymentMethod); // add default payment method
      const paymentMethods = addedPaymentType.length > 0 ? addedPaymentType : defaultPaymentMethod;

      const body = {
        type: customer?._id ? "order" : "walkin",
        items: cart,
        status: "complete",
        payment_method: paymentMethods,
        staff: {
          id: user._id,
          name: user.full_name,
          role: user.account.role
        },
        is_delivery: false,
        customer: customer?._id ? customerData : null,
        delivery_details: {
          status: "pending",
          rider: {
            id: selectedRider._id,
            name: selectedRider.full_name,
            phone: selectedRider.phone,
            plate_number: selectedRider.plate_number,
          },
          recipient: {
            name: customer?.full_name || "",
            phone: customer?.phone || "",
            address: customer?.address || "",
          }
        },
        draft: false,
      };
      if (cart.length === 0) {
        toast.error("Cart Empty");
        return;
      }
      if (customer?._id) {
        const response = await createCustomerSale(body);

        if (response.status === 'ok') {
          setClearedPayment(false)
          toast.success("Sale created successfully");
        }
      } else {
        const response = await createWalkinSale(body);

        if (response.status === 'ok') {
          setClearedPayment(false)
          toast.success("Sale created successfully");
        }
      }

      setShowPrintModal(false);
      localStorage.setItem("cart", JSON.stringify([]));
      setAddedPaymentType([]);
      setCart([]);
      setTotalQuantity(0);
    } catch (error) {
      setAddedPaymentType([]);
      toast.error("Error, try again");
    }
  }

  const addPayment = (amount, type, currentTotalAmount) => {
    if (!amount || !type) {
      toast.error("Amount or type is empty");
      return;
    }

    if(clearedPayment){
      toast.error("Payment already satisfied");
    }

    if (Number(amount) + Number(splitAmount) > getTotalAmount()) {
      toast.error("Amount is greater than total amount");
      return;
    }

    if (Number(amount) + Number(currentTotalAmount) > getTotalAmount()) {
      toast.error("Amount is greater than total amount");
      return;
    }

    if (amount < 0) {
      toast.error("Amount cannot be negative");
      return;
    }

    if (amount > cart.reduce((a, c) => a + c.price * c.quantity, 0)) {
      toast.error("Amount is greater than total amount");
      return;
    }
    if (toggleSplitPayment && splitAmount > 0) {
      if (amount > splitAmount) {
        toast.error("Amount is greater than total amount");
        return;
      }
    }
    const paymentType = addedPaymentType.map(item => item.type);

    if (paymentType.includes(type)) {
      toast.error("Payment type already added");
      return;
    }
    const newPayment = {
      id: Math.floor(Math.random() * 100000),
      type: type,
      amount: amount,
      details: ""
    };
    const newCart = cart.map(item => {
      const newItem = { ...item };
      newItem.total = newItem.price * newItem.quantity;
      return newItem;
    });
    const newCartItems = newCart.map(item => {
      const newItem = { ...item };
      newItem.total = newItem.total - amount;
      return newItem;
    });
    setCart(newCartItems);

    if (splitAmount === 0) {
      setTotalAmount(Number(currentTotalAmount));
    } else {
      setTotalAmount(splitAmount - amount);

      if(splitAmount - amount == 0){
      setClearedPayment(true)
      }
    }
    localStorage.setItem("cart", JSON.stringify(newCartItems));
    setAddedPaymentType([...addedPaymentType, newPayment]);
    setPaymentState({
      type: '',
      amount: 0,
    });
  };


  const addDiscount = (value, type) => {
    if (!value || !type) {
      toast.error("discount value or type is empty");
      return;
    }

    if(clearedPayment){
      toast.error("Payment already satisfied");
    }

    if(type === "amount"){
      if (Number(value) > getTotalAmount()) {
        toast.error("discount applied is greater than total amount");
        return;
      }
    }

    setApplyDiscount(discount);

    if (discount.type === "percentage") {

        toast.info(discount.value +" % Discount applied !")

    } else {
      
        toast.info(discount.value +" ₦ Discount applied !")
    }
    
    setDiscount({
      type: '',
      value: 0,
    });
  };


  const addProduct = id => {
    try {
      const product = products.filter(product => product._id === id);
      const productInCart = myCart.find(product => product.id === id);
      const cartId = Math.random().toString(36).substr(2, 9);
      const name = 'Order-'.concat(cartId);
      if (productInCart) {
        const updatedCart = myCart.map(item => {
          if (item.id === id) {
            item.quantity += 1;
            item.total = item.quantity * item.cart.price;
          }
          return item;
        });
        setCart(updatedCart);
        localStorage.setItem("cart", JSON.stringify(updatedCart));
      } else {
        const productCart = {
          product: product[0],
          quantity: 1,
          price: product[0].price.selling,
          total: product[0].price.selling * 1,
          id: product[0]._id,
        };
        const cartItem = {
          cartId,
          name,
          id: product[0]._id,
          cart: productCart,
          quantity: 1,
        }
        setCart([...myCart, cartItem]);
        localStorage.setItem("cart", JSON.stringify([...myCart, cartItem]));

      }
    } catch (err) {
      toast.error("Error, try again");
    }
  };

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const user = await getUser();
      if (user) {
        const userId = user._id;
        const riderResponse = await getAllRiders(userId, user.businessRef);
        const response = await getAllProducts(userId, user.businessRef);
        const customerResponse = await getAllCustomers(userId, user.businessRef);
        setCustomers(customerResponse.data);
        setProducts(response.data);
        setRiders(riderResponse.data);
        setUser(user);
        setLoading(false);
      }
    }
    fetchData();

  }, []);

  useEffect(() => {
    let total = 0;
    cart.map(product => {
      total += product.quantity;
    }
    );
    setTotalQuantity(total);
  }, [cart]);

  const removeProduct = (id) => {
    const updatedCart = myCart.filter(item => item.id !== id);
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  const removePaymentMethod = (id, amount) => {
    const updatedPayment = addedPaymentType.filter(item => item.id !== id);
    setAddedPaymentType(updatedPayment);
    if(updatedPayment.length === 0) {
      setTotalAmount(0);
    }
    if(updatedPayment.length > 0){
    setTotalAmount(splitAmount + Number(amount));
    }
    setClearedPayment(false)
  };

  const selectCustomer = (e) => {
    const { value } = e.target;
    const customer = customers.find(customer => customer._id === value);
    setCustomer(customer);
  };

  const setCat = (e) => {
    const { name, value } = e.target;
    setFilteredProduct(value);
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const selectRider = (e) => {
    const { value } = e.target;
    const rider = riders.find(rider => rider._id === value);
    setSelectedRider(rider);
  };

  const setSearch = (e) => {
    const { name, value } = e.target;
    setSearchProduct(value);
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const getProductBySearchQuery = (
    products,
    searchQuery,
  ) => {
    return products.filter(product =>
      product.name.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  };

  const getProductByFilteredCategory = (
    products,
    selectedCategory,
  ) => {
    return products.filter(product => product.category.toLowerCase() === selectedCategory.toLowerCase());
  };

  const allProductsArray = useMemo(() => {
    let allProducts = products;
    if (searchProduct) {
      allProducts = getProductBySearchQuery(allProducts, searchProduct);
    }

    if (selectedCategory) {
      allProducts = getProductByFilteredCategory(allProducts, selectedCategory);
    }

    return allProducts || [];
  }, [products, searchProduct, selectedCategory]);


  const categories = [...new Set(products.map(product => product.category))]

  const selectCart = (e) => {
    const { value } = e.target;
    const cartItem = savedCarts.find(order => order.cartId === value);
    setCart(cartItem.cart);
    setCurrentOrder(cartItem.cartId);
    localStorage.setItem('cart', JSON.stringify(cartItem.cart));
  };

  const addRider = () => {
    setRiderModal(false);
    toast.success("Rider added successfully");
  };

  const toggleSplit = useCallback(() => {
    setToggleSplitPayment(prevCheck => !prevCheck);
  }, []);

  const toggleDiscount = useCallback(() => {
    setToggleApplyDiscount(prevCheck => !prevCheck);
  }, []);

  // get total amount
  const getTotalAmount = () => {
    let total = 0;
    cart.map(product => {
      total += product.total;
    }
    );
    return total;
  };


  const updatePaymentType = (e) => {
    const { name, value } = e.target;
    setPaymentState({
      ...paymentState,
      [name]: value,
    });
  };

  const updateDiscount = (e) => {
    const { name, value } = e.target;
    console.log('e.taget', value)
    setDiscount({
      ...discount,
      [name]: value,
    });
  };

  

  const navigateToDashboard = () => {
    //  navigate.history.push("/dashboard");
  }


  const formatMoney = (amount) => {
    return "₦ " + (amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }

  return (
    <>
      <>
        <nav className="navbar default-layout-navbar shadow-sm col-lg-12 col-12 p-0 py-2  fixed-top d-flex flex-row">
          <div className="text-center navbar-brand-wrapper">
            <Link className="navbar-brand brand-logo-mini" to="/"><img src={require('../../../assets/images/onFlare/Logo/logo.svg')} alt="logo" /> Point of Sale</Link>
            {/* <Link className="navbar-brand brand-logo-mini" to="/"><img src={require('../../../assets/images/onFlare/Logo/logo-mini.svg')} alt="logo" className="logo" /></Link> */}
          </div>
          <div className="navbar-menu-wrapper d-flex">

            <div style={{ width: '40%', marginLeft: '200px' }}>
              <select onChange={selectCart} name="cartName" className="form-control input ivu-select ivu-select-single ivu-select-default shadow-sm">
                <option value={''}>Saved Orders</option>
                {savedCarts.map((cart) => (
                  <option key={cart.id} value={cart.cartId}>{cart.name}</option>
                ))}
              </select>
            </div>
            <ul className="navbar-nav navbar-nav-right">
              <li className="nav-item">
                <div className="page-header">

                </div>
              </li>
              <li className="nav-item">
                <Dropdown alignRight>
                  <Dropdown.Toggle className="nav-link count-indicator">
                    <small className='text-primary'>Lekki Branch</small>
                    <i onClick={() => setRiderModal(true)} style={{ cursor: 'pointer', marginLeft: '20px' }} className="mdi mdi-truck-outline text-primary header-icon-right">  </i>
                    <i onClick={() => navigateToDashboard()} className='mdi mdi-home-outline text-primary header-icon-right'></i>
                    <i onClick={() => setRegisterModal(true)} className='mdi mdi-monitor text-primary header-icon-right'></i>
                  </Dropdown.Toggle>
                </Dropdown>
              </li>

              <li className="nav-item nav-profile ">
                <span className="">
                  <Dropdown alignRight>
                    <Dropdown.Toggle className="nav-link pb-1">
                      <div className="nav-profile-img shadow">
                        <img src={require("../../../assets/images/faces/face1.jpg")} alt="user" />
                      </div>
                      <div className="nav-profile-text">
                        <p className="mb-1 text-black"><Trans>{user?.firstName || 'Guest'}</Trans></p>
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="navbar-dropdown">
                      <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()}>
                        <i className="mdi mdi-cached mr-2 text-success"></i>
                        <Trans>Activity Log</Trans>
                      </Dropdown.Item>
                      <Dropdown.Item href="" onClick={logout}>
                        <i className="mdi mdi-logout mr-2 text-primary"></i>
                        <Trans>Sign Out</Trans>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </span>
              </li>
            </ul>
          </div>
        </nav>

      </>
      <div className="page-body-wrapper">
        {/* <POSSidebar /> */}
        <div className="main-panel">
          <div className="content-wrapper-pos content-wrapper bg-white">
            <ToastContainer />

            {loading || products.length === 0 ? (
              <div className="text-center">
                <h1>No products found</h1>
                <Link to="/products" style={{ color: 'white' }} className="btn btn-default">
                  Add Product
                </Link>
              </div>
            ) : (
              <>
                <div className="row">
                  <div className="col-md-7">
                    <div className="row" style={{ marginBottom: '20px' }}>
                      <div className="col-md-6">
                        <input onChange={setSearch} type="text" style={{ border: '10px' }} className="form-control input shadow-sm" value={formState.product} name="product" placeholder="Search for product"></input>
                      </div>
                      <div className="col-md-6">
                        <select onChange={setCat} value={formState.category} name="category" className="form-control input shadow-sm">
                          <option value={''}>Select Category</option>
                          {categories.map((product) => (
                            <option key={product._id} value={product}>{product}</option>
                          ))}
                        </select>
                      </div>
                    </div>

                    {allProductsArray.map((product) => (
                      <div key={product._id} className="pos-grid-item pos-card">
                        <a onClick={() => addProduct(product._id)}>
                          <div className="pos-card-body">
                            <img
                              src={require("../../../assets/images/POS/product.svg")}
                              className="card-img-top"
                              alt="total sales" />
                            <div className="pos-grid-item-name">
                              <p style={{ wordBreak: 'break-word' }}>
                                {product.name}<br></br><strong>{formatMoney(product.price.selling)}</strong>
                              </p>
                            </div>
                          </div>
                        </a>
                      </div>
                    ))}
                  </div>

                  <div className="col-md-5">
                    <div className="card">
                      <div className="ivu-layout-sider-children">
                        <div id="pos-order" className="pos-order" width="400">
                          <div>
                            <div className="above-order-items">
                              <div className="order-info">
                                <button type="button" className="ivu-btn ivu-btn-text ivu-btn-small" style={{ fontSize: '18px', margin: '4px', padding: '4px', height: 'auto' }}>
                                  <span>
                                    <i className="mdi mdi-plus-box-multiple" style={{ fontSize: '18px', color: 'rgb(25, 190, 107)', marginTop: '2px' }}>
                                    </i>
                                  </span>
                                </button>
                                <span className="details customer_selection" style={{ marginRight: '20px', marginLeft: '8px' }}>
                                  <div className="ivu-select ivu-select-single ivu-select-default" remote="">
                                    <div className="ivu-select-selection">
                                      <input type="hidden" value=""></input>
                                      <div className="">
                                        <select onChange={selectCustomer} name="walkinCustomer" className="ivu-select-input">
                                          <option value={'Walkin Customer'}>Walkin Customer</option>
                                          {customers.map((customer) => (
                                            <option key={customer._id} value={customer._id}>{customer.full_name}</option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </span>
                                <span className="other"> Ref: <strong>{cart[0]?.name}</strong>
                                </span>
                              </div>
                              <div className="order-sub-details">
                                <span className="details">Served by:{' '}
                                  <strong>{user.full_name}</strong></span>
                                <span className="other"><
                                  strong>{cart.length}</strong> Item <strong>{totalQuantity} </strong>
                                  Quantity
                                </span>
                              </div>
                            </div>

                            {cart.length === 0 ? (
                              <div className="ivu-card-body">
                                <div data-v-48adf80b="" style={{ textAlign: 'center' }}><br></br>
                                  <i className="mdi mdi-alert mr-2 text-danger" style={{ fontSize: '36px', marginTop: '500px' }}>
                                  </i>
                                  <h3 data-v-48adf80b="" style={{ color: 'rgb(128, 134, 149)', margin: '10px 0px 20px' }}>Product cart is Empty !</h3>
                                  <br data-v-48adf80b="" />
                                </div>
                              </div>
                            ) : (
                              <>
                                <div className="pos-order-items">
                                  <div className="order-items">
                                    <div className="header">
                                      <span className="index">#</span>
                                      <span className="details">Desc</span>
                                      <span className="quantity">Qty</span>
                                      <span className="subtotal">Subtotal</span>
                                    </div>
                                    <div>
                                      <div className="pos-order-scroll scroll-x">
                                        <div className="order-item">
                                          {cart.map((product, index) => (
                                            <span className="item" key={index}>
                                              <span className="index">
                                                {index + 1}
                                                <span onClick={() => removeProduct(product.id)}>
                                                  <i className="pointer mdi mdi-close" style={{ fontSize: '16px', color: 'rgb(237, 64, 20)' }}>

                                                  </i>
                                                </span>
                                              </span>
                                              <span className="details pointer">
                                                <strong>{product.productName}</strong><br></br>
                                                <span>
                                                  {formatMoney(product.price)}
                                                </span>
                                              </span>
                                              <span className="quantity">
                                                <div className="ivu-input-number-input-wra">
                                                  <input type='number' className="custom-select form-control input shadow-sm" readOnly prefix="fa fa-user" value={product.quantity} placeholder="" />
                                                </div>
                                              </span>
                                              <span className="subtotal">
                                                <span>
                                                  {formatMoney(product.total)}
                                                </span>
                                              </span>
                                            </span>
                                          ))}

                                        </div>
                                        {toggleSplitPayment && (
                                          <>

                                            <div style={{ backgroundColor: '#f5f5f5', padding: '5px', overflowY: 'hidden', overflowX: 'auto', width: 'auto', paddingTop: '20px' }}>
                                              <ol>
                                                {addedPaymentType.map((payment) => (
                                                  <li key={payment.id} style={{ fontSize: "13px", marginLeft: '20px' }}>
                                                    Type: {payment.type}, {payment.amount} <i onClick={() => removePaymentMethod(payment.id, payment.amount)} className="pointer mdi mdi-close" style={{ color: 'rgb(237, 64, 20)' }}>
                                                    </i>
                                                  </li>
                                                ))}
                                              </ol>
                                            </div>

                                          </>
                                        )}
                                        <div className="row col-md-12" style={{ backgroundColor: '#f5f5f5', padding: '5px', }}>
                                          <div style={{ marginLeft: '20px' }} className="col-12">
                                            <label htmlFor="" className="mb-0">
                                              Split Payment
                                            </label><br></br>
                                            <label className="label">
                                              <div className="toggle">
                                                <input className="toggle-state" onClick={() => toggleSplit()} type="checkbox" name="hideInPOS" value="check" />
                                                <div className="toggle-inner">
                                                  <div className="indicator"></div>
                                                </div>
                                                <div className="active-bg"></div>
                                              </div>
                                            </label>
                                          </div>

                                          <div style={{ marginLeft: '20px' }} className="col-12">
                                            <label htmlFor="" className="mb-0">
                                              Apply discount to sale
                                            </label><br></br>
                                            <label className="label">
                                              <div className="toggle">
                                                <input className="toggle-state" onClick={() => toggleDiscount()} type="checkbox" name="hideInPOS" value="check" />
                                                <div className="toggle-inner">
                                                  <div className="indicator"></div>
                                                </div>
                                                <div className="active-bg"></div>
                                              </div>
                                            </label>
                                          </div>
                                          {applyDiscount && (
                                            <div className="col-md-12 row" style={{ marginBottom: '20px' }}>
                                              <div className="col-5" style={{ marginLeft: '20px' }}>
                                                <select className="custom-select form-control input shadow-sm" name="type" value={discount.type} onChange={updateDiscount} id="">
                                                  <option>Type</option>
                                                    <option value={'percentage'}>{'Percentage (%)'}</option>
                                                    <option value={'amount'}>{'Amount'}</option>
                                                </select>
                                              </div>
                                              <div className="col-5">
                                                <input type="number" className=" custom-select form-control input shadow-sm" name="value" value={discount.amount} onChange={updateDiscount} id="" />
                                              </div>
                                              <div className="col-md-1">
                                                <i onClick={() => addDiscount(
                                                  discount.value,
                                                  discount.type,
                                                  )}
                                                  title="Add discount"
                                                  className="mdi mdi-plus-box-multiple"
                                                  style={{ float: 'right', fontSize: '18px', color: 'rgb(25, 190, 107)', marginTop: '2px', cursor: 'pointer' }}>
                                                </i>
                                              </div>
                                            </div>
                                          )}
                                          {toggleSplitPayment && (
                                            <div className="col-md-12 row" style={{ marginBottom: '20px' }}>
                                              <div className="col-5" style={{ marginLeft: '20px' }}>
                                                <select className="custom-select form-control input shadow-sm" name="type" value={paymentState.type} onChange={updatePaymentType} id="">
                                                  <option>Type</option>
                                                  {paymentTypes.map((payment) => (
                                                    <option key={payment} value={payment}>{payment}</option>
                                                  ))}
                                                </select>
                                              </div>
                                              <div className="col-5">
                                                <input type="number" className=" custom-select form-control input shadow-sm" name="amount" value={paymentState.amount} onChange={updatePaymentType} id="" />
                                              </div>
                                              <div className="col-md-1">
                                                {!clearedPayment && (
                                                <i onClick={() => addPayment(
                                                  paymentState.amount,
                                                  paymentState.type,
                                                  (splitAmount === 0 ? getTotalAmount() - paymentState.amount : Number(splitAmount)
                                                  ))}
                                                  title="Add payment type"
                                                  className="mdi mdi-plus-box-multiple"
                                                  style={{ float: 'right', fontSize: '18px', color: 'rgb(25, 190, 107)', marginTop: '2px', cursor: 'pointer' }}>
                                                </i>
                                                  )}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="below-order-items">
                                  <div className="order-total">
                                    <p className="total">
                                      Payable Amount
                                      <span className="amount"> <span><span>
                                        {toggleSplitPayment ? (
                                          <>
                                            {splitAmount === 0 ? (
                                              <>
                                                {formatMoney(cart.reduce((a, c) => a + c.price * c.quantity, 0))}
                                              </>
                                            ) : (
                                              <>
                                                Amount to pay {formatMoney(splitAmount)} {appliedDiscount && "discount applied"}
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {formatMoney(cart.reduce((a, c) => a + c.price * c.quantity, 0))}
                                          </>
                                        )}
                                      </span>
                                      </span>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div className="" style={{ alignContent: 'center', marginTop: '20px' }}>
                                  <div className="ivu-btn-group drawer-footer ivu-btn-group-default">
                                    <div className="btn btn-center ivu-tooltip" style={{ alignContent: 'right', }}>
                                      <button type="button" onClick={() => payNow()} className="ivu-btn ivu-btn-success ivu-btn"> <i className="mdi mdi-cash" style={{ color: 'fff', alignContent: 'center' }}>
                                      </i>  <span>
                                          TAKE PAYMENT
                                        </span>
                                      </button>
                                    </div>
                                    <div className="btn btn-left ivu-tooltip">

                                      <div className="ivu-tooltip-rel">
                                        <button type="button" className="ivu-btn ivu-btn-info ivu-btn">
                                          <i className="mdi mdi-information-outline" style={{ color: 'fff' }}>
                                          </i><span>INFO</span>
                                        </button>
                                        <button onClick={() => handleDeleteCart(currentOrder)} type="button" className="ivu-btn ivu-btn-error ivu-btn"> <i className="mdi mdi-delete" style={{ color: 'fff' }}>
                                        </i>  <span> DELETE ORDER</span>
                                        </button>

                                        <button type="button" onClick={() => showPrintReceiptModal('order')} className="ivu-btn ivu-btn-warning ivu-btn"> <i className="mdi mdi-folder" style={{ color: 'fff' }}>
                                        </i>  <span>PRINT INVOICE</span>
                                        </button>

                                        <button type="button" onClick={() => showPrintReceiptModal('print')} className="ivu-btn ivu-btn-primary ivu-btn"> <i className="mdi mdi-printer" style={{ color: 'fff' }}>
                                        </i>  <span>
                                            PRINT ORDER
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {/*   Overview Ends */}
            <Modal
              show={registerModal}
              size="md"
              onHide={() => setRegisterModal(false)}
              backdrop="static"
              keyboard={false}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              style={{ width: "100%", borderRadius: "50px" }}
            >
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                <Register />
              </Modal.Body>
            </Modal>

            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              style={{ width: "100%", borderRadius: "50px" }}
            >
              <Modal.Header closeButton>
              </Modal.Header>
              <PrintReceipt printData={[cart, displayType, appliedDiscount]} />
            </Modal>

            <Modal
              show={showRiderModal}
              onHide={() => setRiderModal(false)}
              backdrop="static"
              keyboard={false}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              style={{ width: "100%", borderRadius: "50px" }}
            >
              <Modal.Header title="Select Rider" closeButton>
              </Modal.Header>
              <div className='row m-0 '>
                <div className="col-md-12 col-xl-12 mx-auto input-container">
                  <div className=' card-body'>
                    <div className="col-12 mt-2">
                      <select className="form-control custom-select input shadow-sm" name="rider" value={selectedRider}
                        onChange={selectRider} id="">
                        <option value={''}>Select Rider</option>
                        {riders.map((rider, index) => (
                          <option key={index} value={rider._id}>{rider.full_name}</option>
                        ))}
                      </select>

                    </div>

                    <div className="col-12 mt-3">
                      <button type="button" style={{ margin: '20px' }} onClick={() => addRider()} className="btn btn-primary rounded-x shadow float-right">Add Rider<i className="mdi mdi-plus" aria-hidden="true"></i></button>
                    </div>

                  </div>

                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default POS;
