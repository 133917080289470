import React , {useState, useEffect} from "react";
import "../../../App.scss";
import { Country } from "country-state-city";
import { createReturnOrder } from '../../../services/ReturnOrderService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUser } from '../../../config/common';

const CreateReturnOrder = () => {
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({
    name: '',
    phoneNumber: '',
    emailAddress: '',
    address: '',
    company: '',
  });

  const createReturnOrderAction = async () => {
    try {
      setFormState({ ...formState });
      const body = {
        name: formState.name,
        phoneNumber: formState.phoneNumber,
        emailAddress: formState.emailAddress,
        address: formState.address,
        company: formState.company,
      }
      await createReturnOrder(body, user._id);

      toast.success("Return Order created successfully");

      setFormState({
        name: '',
        phoneNumber: '',
        emailAddress: '',
        address: '',
        company: '',
      });
    } catch (err) {
      toast.error("Error, try again");
      setFormState({ ...formState });
    }
  };

  const updateForm = (e) => {
    const { value, name } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  useEffect(() => {
    async function fetchData() {
        setLoading(true);
        const user = await getUser();
        if (user) {
            setUser(user);
            setLoading(false);
        }
    }
    fetchData();

}, []);
  return (
    <div className='row m-0 '>    
    
        <div className="col-md-12 col-xl-12 mx-auto input-container"> 
        <div className='card-body'>
        
        <h6  className=" mb-4 col-12"><span>
              <i className="mdi mdi-account-plus"></i> 
            </span> Create Return Order </h6> 
          
        <div className="col-12  mt-3">
            <label htmlFor="" className='mb-0'>Name:</label>
            <input type="text"
              className="form-control input shadow-sm" name="name" value={formState?.name}
              onChange={updateForm} id="" aria-describedby="helpId" placeholder=""/> 
          </div> 

        <div className="col-12 mt-2">
            <label htmlFor="" className='mb-0'>Phone Number:</label>
            <input type="text"
              className="form-control input shadow-sm" name="phone" id="" aria-describedby="helpId" placeholder=""/> 
          </div>

        <div className="col-12 mt-2">
            <label htmlFor="" className='mb-0'>Email:</label>
            <input type="text"
              className="form-control input shadow-sm" name="" id="" aria-describedby="helpId" placeholder=""/> 
          </div>

        <div className="col-12 mt-2">
            <label htmlFor="" className='mb-0'>Address:</label>
            <input type="text"
              className="form-control input shadow-sm" name="" id="" aria-describedby="helpId" placeholder=""/> 
          </div>

          <div className="col-12 mt-2">
            <label htmlFor="" className='mb-0'>Company:</label>
            <input type="text"
              className="form-control input shadow-sm" name="" id="" aria-describedby="helpId" placeholder=""/> 
          </div>


        <div className="col-12 mt-3"> 
            <button type="button" className="btn btn-default rounded-x  border rounded-x shadow">Cancel</button>
            <button style={{marginLeft: '80px'}} type="button" className="btn btn-outline-primary rounded-x  rounded-x shadow">Save & Add New</button>
            <button type="button" onClick={() => createReturnOrderAction()} className="btn btn-primary rounded-x shadow float-right">Create<i className="mdi mdi-plus" aria-hidden="true"></i></button>
          </div> 

        </div>
        </div>
       
    </div>
  );
};

export default CreateReturnOrder;
