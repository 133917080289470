import React, { useState, useEffect, useCallback, useMemo } from "react";
import "../../../App.scss";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import CreateProduct from "./CreateProduct";
import { getAllProducts, deleteProduct } from "../../../services/ProductService";
import { getUser } from "../../../config/common";
import BarCode from "react-barcode";
import ProductDetails from "./ProductDetails";

const Products = () => {
  const [show, setShow] = useState(false);
  const [showAllBarcode, setShowAllBarcode] = useState(false);
  const [createProductModal, setCreateProductModal] = useState(false);
  const handleClose = () => setShow(false);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState([]);
  const [productDetails, setProductDetails] = useState({});
  const [editProductModal, setEditProductModal] = useState(false);
  const [ProductData, setProductData] = useState({});
  const [productDetailsModal, setProductDetailsModal] = useState(false);
  const [searchProduct, setSearchProduct] = useState('');
  const [singleBarcode, setSingleBarcode] = useState({ name: '', barcode: '' , price: ''});
  const [formState, setFormState] = useState({
    product: "",
  });


  const showBarcodeModal = useCallback((barcode, name, price) => {
    setShow(true)
    setSingleBarcode({
      name: name,
      barcode: barcode,
      price: price,
    });
  }, []);

  const showProductDetails = useCallback((product) => {
    setProductDetails(product);
    setProductDetailsModal(true);
  }, []);

  const setSearch = (e) => {
    const { name, value } = e.target;
    setSearchProduct(value);
    setFormState({
      ...formState,
      [name]: value,
    });
  };


  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const user = await getUser();
      if (user) {
        const userId = user._id;
        const response = await getAllProducts(userId, user.businessRef);
        setProducts(response.data);
        setUser(user);
        setLoading(false);
      }
    }
    fetchData();

  }, []);
  const print = () => {
    window.print();
  };

  const closeProductModal = () => setCreateProductModal(false);

  const showCreateProductModal = () => setCreateProductModal(true);

  const getProductBySearchQuery = (
    products,
    searchQuery,
  ) => {
    return products.filter(product =>
      product.name.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  };

  const showEditModal = (data) => {
    setEditProductModal(true);
    setProductData(data);
  };

  const removeProduct = async (id) => {
    const user = await getUser();
    if (user) {
      await deleteProduct(id);
        const newProducts = products.filter(product => product._id !== id);
        setProducts(newProducts);
    }
  };

  const allProductsArray = useMemo(() => {
    let allProducts = products;
    if (searchProduct) {
      allProducts = getProductBySearchQuery(allProducts, searchProduct);
    }

    return allProducts || [];
  }, [products, searchProduct]);


  return (
    <div className="m-0 ">
      <h6 className=" mb-4 col-12">
        <span>
          <i className="mdi mdi-account-multiple "></i>
        </span>{" "}
        Products{" "}
      </h6>

      <div className=" heads shadow-sm border h-75  p-3">
        <div className="  text-right  ">
          <Link
            to="#"
            onClick={() => setShowAllBarcode(true)}
            className="btn btn-primary mr-2 r4 text-white btn-sm p-2 px-4"
          >
            {" "}
            Print All Bar Codes{" "}
          </Link>
          <Link
            to="#"
            onClick={showCreateProductModal}
            className="btn btn-primary mr-2 r4 text-white btn-sm p-2 px-4"
          >
            {" "}
            Add Product{" "}
          </Link>
        </div>
        <hr className="shadow mt-0 pt-0 mt-2" />
        <div className="body">
          <div className="header text-right">
            <span className="mdi db-query mdi-magnify"></span>
            <input onChange={setSearch} value={formState.product} name="product" type="text" className="db-search pl-4" />
          </div>

          <div className="views mt-3 shadow-sm rounded-lg">
            {allProductsArray.length === 0 ? (
              <div className="empty-view" style={{ textAlign: 'center' }}>
                <div className="title">No Product</div>
                <div className="description">
                  You have no product. You can add a product by clicking the
                  button above.
                </div>
              </div>
            ) : (
              <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Name</th>
                    <th scope="col">Cost</th>
                    <th scope="col">Price</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Category</th>
                    <th scope="col">Taxes</th>
                    <th scope="col">Variant</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {allProductsArray.map((product) => (
                    <tr key={product._id}>
                      <th scope="row">
                        <input type={"checkbox"}></input>
                      </th>
                      <td>{product.name}</td>
                      <td>{product.price.cost}</td>
                      <td>{product.price.selling}</td>
                      <td>{product.stock.quantity}</td>
                      <td>{product.category}</td>
                      <td>{product.price.cost}</td>
                      <td>{product.variant.map(
                        (variant) => variant.name
                      )}</td>
                      <td>
                        <span onClick={() => showEditModal(product)} className="mdi mdi-pencil btn btn-primary text-white btn-lg mx-1 r4" title="Edit"></span>
                        <span onClick={() => showProductDetails(product)} className="mdi mdi-eye btn btn-warning text-white btn-lg mx-1 r4" title="View"></span>
                        <span
                          onClick={() => showBarcodeModal(product?._id, product?.name, product?.price?.selling)}
                          className="mdi mdi-qrcode btn btn-warning text-white btn-lg mx-1 r4"
                         title="BarCode"></span>
                        <span onClick={() => removeProduct(product._id)} className="mdi mdi-delete btn btn-danger text-white btn-lg mx-1 r4" title="Delete"></span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ width: "100%", borderRadius: "50px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Print {singleBarcode.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ alignItems: 'center', textAlign: 'center' }}>
          <h4>NGN {singleBarcode.price}</h4>
            <BarCode value={'PROD'+singleBarcode.barcode.slice(0, 5)} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={print} variant="primary">
            Print
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showAllBarcode}
        onHide={() => setShowAllBarcode(false)}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ width: "100%", borderRadius: "50px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Print all BarCodes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {products.map((product) => (
            <div key={product._id} style={{ alignItems: 'center', textAlign: 'center'}}>
              <br></br><h4>{product.name}</h4>
              <h4>NGN {product.price?.selling}</h4>
              <BarCode value={'PROD'+product._id.slice(0, 5)} />
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAllBarcode(false)}>
            Cancel
          </Button>
          <Button onClick={print} variant="primary">
            Print
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal
        show={createProductModal}
        onHide={closeProductModal}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="md"
        style={{ width: "100%", borderRadius: "50px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span>
              <i className="mdi mdi-account-plus"></i>{" "}
            </span>
            Create Product{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateProduct  type={'create'} action={() => setCreateProductModal(false)} />
        </Modal.Body>
      </Modal>

      <Modal
        show={editProductModal}
        onHide={() => setEditProductModal(false)}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="md"
        style={{ width: "100%", borderRadius: "50px" }}
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>

        <CreateProduct type={'update'} data={ProductData} action={() => setEditProductModal(false)}/>

        </Modal.Body>
      </Modal>

      <Modal
        show={productDetailsModal}
        onHide={() => setProductDetailsModal(false)}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="md"
        style={{ width: "100%", borderRadius: "50px" }}
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <ProductDetails product={productDetails} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Products;
