import React, { useState, useEffect } from 'react'
import '../../../App.scss';
import { Country } from 'country-state-city';
import { createRider } from '../../../services/RiderService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUser, generateId } from '../../../config/common';

const CreateRider = () => {
  const items = Country.getAllCountries();

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState([]);
  const [formState, setFormState] = useState({

    full_name: "",
    phone: "",
    vehicle: "",
    plate_number: "",
    company: "",
    address: "",
    city: "",
    state: ""

  });

  const createRiderAction = async () => {
    try {
      setFormState({ ...formState });
      const body = {
        businessRef: user.businessRef,
        full_name: formState.full_name,
        phone: formState.phone,
        vehicle: formState.vehicle,
        plate_number: formState.plate_number,
        company: formState.company,
        location: {
          address: formState.address,
          city: formState.city,
          state: formState.state
        }
      }
      const response = await createRider(body, user._id);

      if (response.status === 'ok') {
        toast.success("Rider created successfully");
      
      }

      setFormState({
        full_name: "",
        phone: "",
        vehicle: "",
        plate_number: "",
        company: "",
        address: "",
        city: "",
        state: ""
      });
    } catch (err) {
      toast.error("Error, try again");
      setFormState({ ...formState });
    }
  };

  const updateForm = (e) => {
    const { value, name } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const user = await getUser();
      if (user) {
        setUser(user);
        setLoading(false);
      }
    }
    fetchData();

  }, []);
  return (
    <div className='row m-0 '>
      <ToastContainer />

      <div className="col-md-12 col-xl-12 mx-auto input-container">
        <div className='card-body'>

          <h6 className=" mb-4 col-12"><span>
            <i className="mdi mdi-account-plus"></i>
          </span> Create Rider </h6>

          <div className="col-12  mt-3">
            <label htmlFor="" className='mb-0'>Name:</label>
            <input type="text"
              className="form-control input shadow-sm" name="full_name" value={formState?.full_name}
              onChange={updateForm} id="" aria-describedby="helpId" placeholder="" />
          </div>

          <div className="col-12 mt-2">
            <label htmlFor="" className='mb-0'>Phone Number:</label>
            <input type="text"
              className="form-control input shadow-sm" name="phone" value={formState?.phone}
              onChange={updateForm} id="" aria-describedby="helpId" placeholder="" />
          </div>

          <div className="col-12 mt-2">
            <label htmlFor="" className='mb-0'>Vehicle:</label>
            <input type="text"
              className="form-control input shadow-sm" name="vehicle" value={formState?.vehicle}
              onChange={updateForm} id="" aria-describedby="helpId" placeholder="" />
          </div>

          <div className="col-12 mt-2">
            <label htmlFor="" className='mb-0'>Plate Number:</label>
            <input type="text"
              className="form-control input shadow-sm" name="plate_number" value={formState?.plate_number}
              onChange={updateForm} id="" aria-describedby="helpId" placeholder="" />
          </div>

          <div className="col-12 mt-2">
            <label htmlFor="" className='mb-0'>Address:</label>
            <input type="text"
              className="form-control input shadow-sm" name="address" value={formState?.address}
              onChange={updateForm} id="" aria-describedby="helpId" placeholder="" />
          </div>

          <div className="col-12 mt-2">
            <label htmlFor="" className='mb-0'>Company:</label>
            <input type="text"
              className="form-control input shadow-sm" name="company" value={formState?.company}
              onChange={updateForm} id="" aria-describedby="helpId" placeholder="" />
          </div>

          <div className="col-12 mt-2">
            <label htmlFor="" className='mb-0'>City:</label>
            <input type="text"
              className="form-control input shadow-sm" name="city" value={formState?.city}
              onChange={updateForm} id="" aria-describedby="helpId" placeholder="" />
          </div>

          <div className="col-12 mt-2">
            <label htmlFor="" className='mb-0'>State:</label>
            <input type="text"
              className="form-control input shadow-sm" name="state" value={formState?.state}
              onChange={updateForm} id="" aria-describedby="helpId" placeholder="" />
          </div>


          <div className="col-12 mt-3">
            <button type="button" className="btn btn-default rounded-x  border rounded-x shadow">Cancel</button>
            {/* <button style={{ marginLeft: '80px' }} type="button" className="btn btn-outline-primary rounded-x  rounded-x shadow">Save & Add New</button> */}
            <button type="button" onClick={() => createRiderAction()} className="btn btn-primary rounded-x shadow float-right">Create<i className="mdi mdi-plus" aria-hidden="true"></i></button>
          </div>

        </div>
      </div>

    </div>
  )
}

export default CreateRider;  