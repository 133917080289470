import React, {useState, useEffect}  from 'react'
import '../../../App.scss';  
import { Link } from "react-router-dom";
import CreatUser from './CreateUser';
import {  Modal } from "react-bootstrap";
import { getAllUsers } from '../../../services/UserService';
import { getUser } from '../../../config/common';


const Users=()=> { 
  const [createUserModal, setCreateUserModal] = useState(false);
  const [users, setUsers] = useState([]);

  const closeUserModal = () => setCreateUserModal(false);
  const showCreateUserModal = () => setCreateUserModal(true);

  useEffect(() => {
    async function fetchData() {
      const user = await getUser();
      if (user) {
        const response = await getAllUsers();
        setUsers(response.data);
      }
    }
    fetchData();

  }, []);

  console.log('users', users)
  return ( 
    <div className='m-0 '> 
      <h6  className=" mb-4 col-12"><span>
              <i className="mdi mdi-account-multiple "></i>  
            </span> Users </h6>  

          <div className=" heads shadow-sm border h-75  p-3"> 
            <div className="  text-right  ">
              <Link to='#' onClick={showCreateUserModal} className="btn btn-primary mr-2 r4 text-white btn-sm p-2 px-4" > Add User </Link>
            </div>
            <hr className='shadow mt-0 pt-0 mt-2'  />
            <div className="body">
             <div className="header text-right">
             <span className="mdi db-query mdi-magnify"></span>
              <input type="text" className='db-search pl-4' />
             </div>

            <div className="views mt-3 shadow-sm rounded-lg">
          {/* <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">First</th>
                <th scope="col">Last</th>
                <th scope="col">Handle</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row"><input type={'checkbox'}></input></th>

                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
                <td>
                  <span className="mdi mdi-pencil btn btn-primary text-white btn-md mx-1 r4" title="Edit"></span>
                  <span className="mdi mdi-keyboard-backspace btn btn-back text-white btn-md mx-1 r4"></span>
                  <span className="mdi mdi-delete btn btn-danger text-white btn-md mx-1 r4" title="Delete"></span>
                </td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
                <td>
                  <span className="mdi mdi-pencil btn btn-primary text-white btn-md mx-1 r4" title="Edit"></span>
                  <span className="mdi mdi-keyboard-backspace btn btn-back text-white btn-md mx-1 r4"></span>
                  <span className="mdi mdi-delete btn btn-danger text-white btn-md mx-1 r4" title="Delete"></span>
                </td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td >Larry the Bird</td>
                <td >Pepsi</td>
                <td>@twitter</td>
                <td>
                  <span className="mdi mdi-pencil btn btn-primary text-white btn-md mx-1 r4" title="Edit"></span>
                  <span className="mdi mdi-keyboard-backspace btn btn-back text-white btn-md mx-1 r4"></span>
                  <span className="mdi mdi-delete btn btn-danger text-white btn-md mx-1 r4" title="Delete"></span>
                </td>
              </tr>  
            </tbody>
          </table> */}
            </div>
              
            </div>
            

             
          </div>   
          <Modal
        show={createUserModal}
        onHide={closeUserModal}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        style={{ width: "100%", borderRadius: "50px" }}
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body style={{width: '100%'}}>

        <CreatUser/>

        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Users; 