import React, {useState, useEffect, useMemo} from "react";
import "../../../App.scss";
import { Country } from "country-state-city";
import { createSupplier, updateSupplier } from '../../../services/SupplierService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUser } from '../../../config/common';
const CreateSupplier = (data) => {
  const items = Country.getAllCountries();
  const [createSuppliersModal, setSuppliersModal] = useState(false);
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({
    businessRef: "",
    full_name: "",
    email: "",
    phone: "",
    company: "",
    location: "",
    city: "",
    state: "",
    country: "",
  });

  const supplierData = data.data || {}; 
  
  useMemo (() => {
    if (supplierData._id) {
    setFormState({
      ...formState,
      full_name: supplierData.full_name || '',
      company: supplierData.company || '',
      phone: supplierData.phone || '',
      email: supplierData.email || '',
      address: supplierData.address || '',
      country: supplierData.country || '',
    })
  }
  }, [supplierData])

  const updateSupplierAction = async () => {
    const body = {
      businessRef: user.businessRef,
      full_name: formState.full_name,
      email: formState.email,
      phone: formState.phone,
      company: formState.company,
      location: formState.location,
      city: formState.city,
      state: formState.state,
      country: formState.country,
    }
    if(data.type === 'create') {
      const response = await createSupplier(body, user._id, body.businessRef);

      if (response.status === 'ok') {
        toast.success("Supplier created successfully");
        setSuppliersModal(false);

      }
    } else {
      const response = await updateSupplier(body, supplierData._id);

      if (response.status === 'ok') {
        toast.success("Supplier updated successfully");
      }
    }
    
    try {
      setFormState({ ...formState });
      
      setFormState({
        businessRef: "",
        full_name: "",
        email: "",
        phone: "",
        company: "",
        location: "",
        city: "",
        state: "",
        country: "",
      });
    } catch (err) {
      toast.error("Error, try again");
      setFormState({ ...formState });
    }
  };

  const updateForm = (e) => {
    const { value, name } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  useEffect(() => {
    async function fetchData() {
        setLoading(true);
        const user = await getUser();
        if (user) {
            setUser(user);
            setLoading(false);
        }
    }
    fetchData();

}, []);
  return (
    <div className='row m-0 '>
      <ToastContainer/>
      <div className="col-md-12 col-xl-12 mx-auto input-container">
        <div className='card-body'>

          <h6 className=" mb-4 col-12"><span>
            <i className="mdi mdi-account-plus"></i>
          </span>  {data.type === 'create' ? 'Create' : 'Update' } Supplier </h6>

          <div className="col-12  mt-3">
            <label htmlFor="" className='mb-0'>Name:</label>
            <input type="text"
              className="form-control input shadow-sm" name="full_name" value={formState?.full_name}
              onChange={updateForm} id="" aria-describedby="helpId" placeholder="" />
          </div>

          <div className="col-12 mt-2">
            <label htmlFor="" className='mb-0'>Phone Number:</label>
            <input type="text"
              className="form-control input shadow-sm" name="phone" value={formState?.phone}
              onChange={updateForm} id="" aria-describedby="helpId" placeholder="" />
          </div>

          <div className="col-12 mt-2">
            <label htmlFor="" className='mb-0'>Email:</label>
            <input type="text"
              className="form-control input shadow-sm" name="email" value={formState?.email}
              onChange={updateForm} id="" aria-describedby="helpId" placeholder="" />
          </div>

          <div className="col-12 mt-2">
            <label htmlFor="" className='mb-0'>Address:</label>
            <input type="text"
              className="form-control input shadow-sm" name="address" value={formState?.address}
              onChange={updateForm} id="" aria-describedby="helpId" placeholder="" />
          </div>

          <div className="col-12 mt-2">
            <label htmlFor="" className='mb-0'>Company:</label>
            <input type="text"
              className="form-control input shadow-sm" name="company" value={formState?.company}
              onChange={updateForm} id="" aria-describedby="helpId" placeholder="" />
          </div>

          <div className="col-12 mt-2">
            <label htmlFor="" className='mb-0'>Location:</label>
            <input type="text"
              className="form-control input shadow-sm" name="location" value={formState?.location}
              onChange={updateForm} id="" aria-describedby="helpId" placeholder="" />
          </div>


          <div className="col-12 mt-2">
            <label htmlFor="" className='mb-0'>City:</label>
            <input type="text"
              className="form-control input shadow-sm" name="city" value={formState?.city}
              onChange={updateForm} id="" aria-describedby="helpId" placeholder="" />
          </div>

          <div className="col-12 mt-2">
            <label htmlFor="" className='mb-0'>State:</label>
            <input type="text"
              className="form-control input shadow-sm" name="state" value={formState?.state}
              onChange={updateForm} id="" aria-describedby="helpId" placeholder="" />
          </div>

          <div className="col-12 mt-2">
            <label htmlFor="" className='mb-0'>Country:</label>
            <select className="form-control input shadow-sm" onChange={updateForm} name="country" value={formState?.country}>
              <option value="">Select Country</option>
              {items.map((item) => {
                return <option key={item.name} value={item.name}>{item.name}</option>
              }
              )}
            </select>
          </div>


          <div className="col-12 mt-3">
            <button type="button" className="btn btn-default rounded-x  border rounded-x shadow">Cancel</button>
           
            <button type="button" onClick={() => updateSupplierAction()} className="btn btn-primary rounded-x shadow float-right">{data.type === 'create' ? 'Create' : 'Update' }<i className="mdi mdi-plus" aria-hidden="true"></i></button>
          </div>

        </div>
      </div>

    </div>
  );
};

export default CreateSupplier;
