import React, {  useState } from "react";
import "../../../assets/css/dashboard.css";
// import Table from 'react-bootstrap/Table';

const ConfirmSales = () => {

const [paymentMethods, setPaymentMethods] = useState({});

const handlePaymentMethod = (cash, method) => {
  console.log(cash, method);
  const paymentData = {
    cash: cash,
    method: method, 
  }
  setPaymentMethods(paymentData);
};

console.log('paymentMethods', paymentMethods);

    return (
      <div style={{marginLeft: '100px'}}>
        <div className="page-header">
          <h3 className="page-title">
            <span className="">
              <i className="mdi mdi-home"></i>
            </span>
            Point of Sales
          </h3>
          <nav aria-label="breadcrumb"></nav>
        </div>
        {/*   Overview Start */}

        <div className="row">
          <div className="col-md-7">
          <div class="pos-grid-item pos-card">
        <a href="">
        <div class="pos-card-body">
          <img
            src={require("../../../assets/images/POS/product.svg")}
            className="card-img-top"
            alt="total sales"
          />
          <div class="pos-grid-item-name">
            <p style={{wordBreak: 'break-word'}}>
              Item 25<br></br>₦<strong>25,000</strong>
            </p>
          </div>
        </div>
        </a>
        </div>

        <div class="pos-grid-item pos-card">
        <a href="">
        <div class="pos-card-body">
          <img
            src={require("../../../assets/images/POS/product.svg")}
            className="card-img-top"
            alt="total sales"
          />
          <div class="pos-grid-item-name">
            <p style={{wordBreak: 'break-word'}}>
              Item 25<br></br>₦<strong>25,000</strong>
            </p>
          </div>
        </div>
        </a>
        </div>

        <div class="pos-grid-item pos-card">
        <a href="">
        <div class="pos-card-body">
          <img
            src={require("../../../assets/images/POS/product.svg")}
            className="card-img-top"
            alt="total sales"
          />
          <div class="pos-grid-item-name">
            <p style={{wordBreak: 'break-word'}}>
              Item 25<br></br>₦<strong>25,000</strong>
            </p>
          </div>
        </div>
        </a>
        </div>

        <div class="pos-grid-item pos-card">
        <a href="">
        <div class="pos-card-body">
          <img
            src={require("../../../assets/images/POS/product.svg")}
            className="card-img-top"
            alt="total sales"
          />
          <div class="pos-grid-item-name">
            <p style={{wordBreak: 'break-word'}}>
              Item 25<br></br>₦<strong>25,000</strong>
            </p>
          </div>
        </div>
        </a>
        </div>

        <div class="pos-grid-item pos-card">
        <a href="">
        <div class="pos-card-body">
          <img
            src={require("../../../assets/images/POS/product.svg")}
            className="card-img-top"
            alt="total sales"
          />
          <div class="pos-grid-item-name">
            <p style={{wordBreak: 'break-word'}}>
              Item 25<br></br>₦<strong>25,000</strong>
            </p>
          </div>
        </div>
        </a>
        </div>

        <div class="pos-grid-item pos-card">
        <a href="">
        <div class="pos-card-body">
          <img
            src={require("../../../assets/images/POS/product.svg")}
            className="card-img-top"
            alt="total sales"
          />
          <div class="pos-grid-item-name">
            <p style={{wordBreak: 'break-word'}}>
              Item 25<br></br>₦<strong>25,000</strong>
            </p>
          </div>
        </div>
        </a>
        </div>

        <div class="pos-grid-item pos-card">
        <a href="">
        <div class="pos-card-body">
          <img
            src={require("../../../assets/images/POS/product.svg")}
            className="card-img-top"
            alt="total sales"
          />
          <div class="pos-grid-item-name">
            <p style={{wordBreak: 'break-word'}}>
              Item 25<br></br>₦<strong>25,000</strong>
            </p>
          </div>
        </div>
        </a>
        </div>

        <div class="pos-grid-item pos-card">
        <a href="">
        <div class="pos-card-body">
          <img
            src={require("../../../assets/images/POS/product.svg")}
            className="card-img-top"
            alt="total sales"
          />
          <div class="pos-grid-item-name">
            <p style={{wordBreak: 'break-word'}}>
              Item 25<br></br>₦<strong>25,000</strong>
            </p>
          </div>
        </div>
        </a>
        </div>
          </div>

          <div className="col-md-5">
            <div className="card">
              <div className="card-body" style={{height: '100%'}}>
                <div className="row">
                  <div className="col-md-6">
                  <h6>Total Amount:</h6>
                    </div>
                  <div className="col-md-6">
                  <input type={"text"} className='form-control custom-select input shadow-sm' value={"100,000"}></input>
                    </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                  <h6>Payment Method:</h6>
                    </div>
                  <div className="col-md-6">
                <select
                  className="form-control custom-select input shadow-sm"
                  name=""
                  id=""
                >
                  <option>Select One</option>
                  <option>2</option>
                  <option>3</option>
                </select>
                    </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                  <h6>Paid:</h6>
                    </div>
                  <div className="col-md-6">
                    <div className="">
                  <input type={"text"} className='form-control custom-select input shadow-sm' value={"100,000"}></input>
                  </div>
                  <div className="">
                  <button className="btn btn-sm btn-success">
                  Exact Amount
                </button>
                </div>
                    </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                  <h6>Change:</h6>
                    </div>
                  <div className="col-md-6">
                  <input type={"text"} className='form-control custom-select input outline-danger shadow-sm' value={"100,000"}></input>
                    </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                  <h6>Split Payment:</h6>
                    </div>
                  <div className="col-md-6">
                  <label class="label">
                    <div class="toggle">
                      <input class="toggle-state" type="checkbox" name="check" value="check" />
                      <div class="toggle-inner">
                        <div class="indicator"></div>
                      </div>
                      <div class="active-bg"></div>
                    </div>
                  </label>
                    </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                  <h6>Payment Method:</h6>
                    </div>
                  <div className="col-md-6">
                    <select
                    className="form-control custom-select input shadow-sm"
                    name=""
                    id=""
                  >
                    <option>Select One</option>
                    <option>2</option>
                    <option>3</option>
                  </select>
                  <div className="row">
                  <div className="col-md-6">
                  <input type={"text"} className='form-control custom-select input shadow-sm' value={""}></input>
                  </div>
                  <div className="col-md-6">
                  <button className="btn btn-sm btn-info" onClick={() => handlePaymentMethod(100, 'Cash') } style={{marginTop: '10px'}}>
                  Add
                </button>
                </div>
                </div>
                </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                  <h6>Payment Details:</h6>
                  <textarea type={"text"} className='form-control custom-select input outline-danger' value={[`${paymentMethods.cash || ''}`  + '#' + `${paymentMethods.method || ''}`]}></textarea>
                    </div>
                </div>

              </div>
              <div className="card-footer">
                <button className="btn btn-md btn-danger">
                  <i className="mdi mdi-trash-can icon-sm  align-middle"></i>{" "}
                  Cancel
                </button>
                
                <button className="btn btn-md btn-success" style={{float: 'right'}}>
                  <i className="mdi mdi-clipboard-text-outline icon-sm  align-middle"></i>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*   Overview Ends */}
      </div>
    );
  }

export default ConfirmSales;
