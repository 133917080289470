import React from "react";
import '../../../App.scss';
import Navbar from '../../../components/layout/admin/Navbar';
import Sidebar from '../../../components/layout/admin/Sidebar';
import Purchase from "../../../components/Admin/Purchase/Purchase";

const PurchaseView = () => {

  return (
    <>
      <div className="container-scroller">
      <Navbar />
        <div className="page-body-wrapper">
        <Sidebar />
          <div className="main-panel">
            <div className="content-wrapper bg-white">
              <Purchase /> 
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PurchaseView;
