import React, { useState, useEffect } from 'react'
import '../../../App.scss';
import { Country } from 'country-state-city';
import { createCustomer, updateCustomer } from '../../../services/CustomerService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUser, generateId } from '../../../config/common';
import { useMemo } from 'react';

const CreatCustomer = (data) => {
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({
    full_name: '',
    company: '',
    phone: '',
    email: '',
    address: '',
    country: '',
  });
  const customerData = data.data || {};

  useMemo(() => {
    if (customerData._id) {
      setFormState({
        ...formState,
        full_name: customerData.full_name || '',
        company: customerData.company || '',
        phone: customerData.phone || '',
        email: customerData.email || '',
        address: customerData.address || '',
        country: customerData.country || '',
      })
    }
  }, [customerData])


  const items = Country.getAllCountries();

  const updateCustomerAction = async () => {
    try {
      setFormState({ ...formState });
      const body = {
        businessRef: user.businessRef,
        email: formState.email,
        phone: formState.phone,
        company: formState.company,
        location: {
          country: formState.country
        },
        full_name: formState.full_name,
      }

      if (data.type === 'create') {
        const response = await createCustomer(body);

        if (response.status === 'ok') {
          toast.success("Customer created successfully");
        }
      } else {
        const response = await updateCustomer(body, customerData._id);

        if (response.status === 'ok') {
          toast.success("Customer updated successfully");
        }
      }
      setFormState({
        full_name: '',
        company: '',
        phone: '',
        email: '',
        address: '',
        country: '',
      });
    } catch (err) {
      toast.error("Error, try again");
      setFormState({ ...formState });
    }
  };

  const updateForm = (e) => {
    const { value, name } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const user = await getUser();
      if (user) {
        setUser(user);
        setLoading(false);
      }
    }
    fetchData();

  }, []);

  return (
    <div className='row m-0 '>
      <ToastContainer />
      <div className="col-md-12 col-xl-12 mx-auto input-container">
        <div className=' card-body'>
          <h6 className=" mb-4 col-12"><span>
            <i className="mdi mdi-account-plus"></i>
          </span> {data.type === 'create' ? 'Create' : 'Update'} Customer </h6>

          <div className="col-12  mt-3">
            <label htmlFor="" className='mb-0'>Name:</label>
            <input type="text"
              className="form-control input shadow-sm" name="full_name" value={formState?.full_name}
              onChange={updateForm} id="" aria-describedby="helpId" placeholder="" />
          </div>


          <div className="col-12 mt-2">
            <label htmlFor="" className='mb-0'>Company:</label>
            <input type="text"
              className="form-control input shadow-sm" name="company" value={formState?.company}
              onChange={updateForm} id="" aria-describedby="helpId" placeholder="" />
          </div>

          <div className="col-12 mt-2">
            <label htmlFor="" className='mb-0'>Phone:</label>
            <input type="text"
              className="form-control input shadow-sm" name="phone" value={formState?.phone}
              onChange={updateForm} id="" aria-describedby="helpId" placeholder="" />
          </div>

          <div className="col-12 mt-2">
            <label htmlFor="" className='mb-0'>Email:</label>
            <input type="text"
              className="form-control input shadow-sm" name="email" value={formState?.email}
              onChange={updateForm} id="" aria-describedby="helpId" placeholder="" />
          </div>

          <div className="col-12 mt-2">
            <label htmlFor="" className='mb-0'>Address:</label>
            <input type="text"
              className="form-control input shadow-sm" name="address" value={formState?.address}
              onChange={updateForm} id="" aria-describedby="helpId" placeholder="" />
          </div>
          <div className="col-12 mt-2">
            <div className="row m-0 p-0">
              <div className="col-5 mr-auto mt-2 p-0">
                <label htmlFor="" className='mb-0'>Country:</label>
                <select className="form-control custom-select input shadow-sm" name="country" value={formState?.country}
                  onChange={updateForm} id="">
                  {items.map((item) => {
                    return <option key={item.name} value={item.name}>{item.name}</option>
                  }
                  )}
                </select>
              </div>

              <div className="col-5 ml-auto mt-2 p-0">
                <label htmlFor="" className='mb-0 '>Customer Group:</label>
                <select className="form-control custom-select input shadow-sm" name="custGroup" value={formState?.custGroup}
                  onChange={updateForm} id="">
                  <option >1</option>
                  <option>2</option>
                  <option>3</option>
                </select>
              </div>
            </div>
          </div>

          <div className="col-12 mt-2">
            <label htmlFor="" className='mb-0'>Opening Balance:</label>
            <input type="text"
              className="form-control input shadow-sm" name="firstName" value={formState?.firstName}
              onChange={updateForm} id="" aria-describedby="helpId" placeholder="" />
          </div>
          <div className="col-12 mt-3">
            <button type="button" className="btn rounded-circle  border rounded-x shadow"><i className="mdi mdi-keyboard-backspace" aria-hidden="true"></i></button>
            {data.type === 'create' ?
              <button type="button" onClick={() => updateCustomerAction()} className="btn btn-primary rounded-x shadow float-right">Create<i className="mdi mdi-plus" aria-hidden="true"></i></button>
              :
              <button type="button" onClick={() => updateCustomerAction()} className="btn btn-primary rounded-x shadow float-right">Update<i className="mdi mdi-plus" aria-hidden="true"></i></button>
            }
          </div>

        </div>

      </div>
    </div>
  )
}

export default CreatCustomer;  