import request from 'umi-request';
import { API_URL, USER_TOKEN } from '../config/config';

export async function getAllReturnOrders(userId, ref) {
  return request(`${API_URL}/api/returnOrder/${userId}?ref=${ref}`, {
    method: 'get',
    headers: {
       'Content-Type': 'application/json',
       'Authorization': USER_TOKEN,
    },
    params: {
      id: userId,
      ref: ref,
    },
  });
}


export async function getReturnOrder(userId, id) {
  return request(`${API_URL}/api/returnOrder/?id=${id}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
    params: {
      userId: userId,
      id: id,
    },
  });
}

export async function createReturnOrder(body, userId) {
  return request(`${API_URL}/api/sale/return`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
    body: JSON.stringify(body),
  });
}

export async function updateReturnOrder(body, userId, customerId) {
  return request(`${API_URL}/api/returnOrder/${userId}?id=${customerId}`, {
    method: 'patch',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
    body: JSON.stringify(body),
  });
}


export async function deleteReturnOrder(userId, customerId, ref) {
  return request(`${API_URL}/api/returnOrder/${userId}?id=${customerId}&ref=${ref}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
    params: {
      id: userId,
      ref: ref,
    },
  });
}
