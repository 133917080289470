import React, { useState } from "react";
import { FormCheck } from "react-bootstrap";
import { Container, Row, Form, Col, Button } from "react-bootstrap";
import Logo from "../elements/Logo";
import { createUser } from "../../services/Auth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { setUserSession } from '../../utils/common';

const Registration = () => {

  const [formState, setFormState] = useState({
    name: '',
    companyName: '',
    email: '',
    accountType: 'business',
    password: '',
    confirmPassword: '',
    accountTitle: 'manager',
  });

  const businessCategory = [
    'Food and Drinks',
    'Salon and Beauty',
    'Fashion and Accessories',
    'Gym and Fitness',
    'Travel and Hotel',
    'Home and Gifts',
    'Washing and Cleaning',
    'Gadgets and Electronics',
  ];

  const createUserAction = async () => {
    try {
      setFormState({ ...formState });
      const body = {
        full_name: formState.name,
        company: formState.companyName,
        email: formState.email,
        password: formState.password,
        account: {
          type: formState.accountType,
          role: 'manager',
        }
      }
      if (body.name === '' || body.companyName === '' || body.email === '' || body.password === '') {
        toast.error('Please fill all the fields');
        return;
      }
      const response = await createUser(body);
      console.log('response', response);
      if (response.status === 'ok') {
        toast.success("User created successfully, please login");
        window.location.href = "/login";
      }

      setFormState({
        name: '',
        companyName: '',
        email: '',
        accountType: 'business',
        password: '',
        confirmPassword: '',
        accountTitle: 'manager',
      });
    } catch (err) {
      toast.error("Error, please try again later");
      setFormState({ ...formState });
    }
    // console.log(body)
  };

  const updateForm = (e) => {
    const { value, name } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
    console.log(value)
  };

  console.log('form', formState)
  return (
    <Row>
      <Col md={1} style={{ margin: "5px" }}>
        <Logo />
      </Col>
      <Col className="authForm" md={5}>
        <Container>
          <ToastContainer />
          <Form className="authFormInput">
            <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
              <div style={{ float: "right" }}>
                <h4>Create account</h4>
                <p>
                  Get access to premium features now
                </p>
              </div>
              <Form.Label column sm="3">
                Name
              </Form.Label>
              <Col sm="9">
                <Form.Control type="text" onChange={updateForm} name="name" defaultValue="" />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
              <Form.Label column sm="3">
                Business Name
              </Form.Label>
              <Col sm="9">
                <Form.Control onChange={updateForm} name="companyName" defaultValue="" />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
              <Form.Label column sm="3">
                Email
              </Form.Label>
              <Col sm="9">
                <Form.Control onChange={updateForm} name="email" defaultValue="" />
              </Col>
            </Form.Group>


            <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
              <Form.Label column sm="3">
                Business Category
              </Form.Label>
              <Col sm="9">
                <select onChange={updateForm} name="accountType" className="form-control mb-3">
                  <option value={''}>Business Category</option>
                  {businessCategory.map((business) => (
                  <option value={business}>{business}</option>
                  ))} 
                </select>
              </Col>
            </Form.Group>
            
            <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
              <Form.Label column sm="3">
                Password
              </Form.Label>
              <Col sm="9">
                <Form.Control onChange={updateForm} name="password" type="password" defaultValue="" />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
              <Form.Label column sm="3">
                Repeat Password
              </Form.Label>
              <Col sm="9">
                <Form.Control onChange={updateForm} name="confirmPassword" type="password" defaultValue="" />
              </Col>
            </Form.Group>

            <hr></hr>

            <div style={{ float: "right" }}>

              <div>
                <Button
                  // type="submit"
                  style={{ float: "right", marginTop: "20px" }}
                  className="primary"
                  onClick={() => createUserAction()}
                >
                  Create my account
                </Button>
              </div>
            </div>
            <Form.Group controlId="formPlaintextPassword">
              <Row md={12}>
                <Col sm="1">
                  <FormCheck inline type="checkbox" label=""></FormCheck>
                </Col>

                <Col sm="11">
                  <p className="sub-menu-item">I've read and accepted the <a href="terms"><span style={{ fontWeight: 'bold' }}>Terms & Conditions</span></a></p>
                </Col>
              </Row>
            </Form.Group>
            <div className="authLink">
              <p>Already have an account? <a href="/login">Sign In</a></p>
            </div>
          </Form>

        </Container>
      </Col>
      <Col className="authBackgroundRegistration"></Col>
    </Row>
  );
};

export default Registration;
