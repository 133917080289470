import React, {useState}  from 'react'
import '../../../App.scss';  
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import CreateReturnOrder from './CreateReturnOrder';


const ReturnOrders=()=> { 
  const [createReturnOrderModal, setReturnOrdersModal] = useState(false);

  const closeReturnOrderModal = () => setReturnOrdersModal(false);
  const showCreateReturnOrderModal = () => setReturnOrdersModal(true);
  return ( 
    <div className='m-0 '> 
      <h6  className=" mb-4 col-12"><span>
              <i className="mdi mdi-account-multiple "></i>  
            </span> Return Orders </h6>  

          <div className=" heads shadow-sm border h-75  p-3"> 
            <div className="  text-right  ">
              <Link onClick={showCreateReturnOrderModal} className="btn btn-primary mr-2 r4 text-white btn-sm p-2 px-4" > Add Return Order </Link>
            </div>
            <hr className='shadow mt-0 pt-0 mt-2'  />
            <div className="body">
             <div className="header text-right">
             <span className="mdi db-query mdi-magnify"></span>
              <input type="text" className='db-search pl-4' />
             </div>

            <div className="views mt-3 shadow-sm rounded-lg">
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Date</th>
                <th scope="col">Details</th>
                <th scope="col">Customers</th>
                <th scope="col">Reference </th>
                <th scope="col">Grand Total</th>
                <th scope="col">Created At</th>
                <th scope="col">Created By</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row"><input type={'checkbox'}></input></th>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
                <td>@mdo</td>
                <td>@mdo</td>
                <td>@mdo</td>
                <td>@mdo</td>
                <td>
                  <span className="mdi mdi-pencil btn btn-primary text-white btn-md mx-1 r4" title="Edit"></span>
                  <span className="mdi mdi-keyboard-backspace btn btn-back text-white btn-md mx-1 r4"></span>
                  <span className="mdi mdi-delete btn btn-danger text-white btn-md mx-1 r4" title="Delete"></span>
                </td>
              </tr>
              <tr>
                <th scope="row"><input type={'checkbox'}></input></th>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
                <td>@mdo</td>
                <td>@mdo</td>
                <td>@mdo</td>
                <td>@mdo</td>
                <td>
                  <span className="mdi mdi-pencil btn btn-primary text-white btn-md mx-1 r4" title="Edit"></span>
                  <span className="mdi mdi-keyboard-backspace btn btn-back text-white btn-md mx-1 r4"></span>
                  <span className="mdi mdi-delete btn btn-danger text-white btn-md mx-1 r4" title="Delete"></span>
                </td>
              </tr><tr>
                <th scope="row"><input type={'checkbox'}></input></th>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
                <td>@mdo</td>
                <td>@mdo</td>
                <td>@mdo</td>
                <td>@mdo</td>
                <td>
                  <span className="mdi mdi-pencil btn btn-primary text-white btn-md mx-1 r4" title="Edit"></span>
                  <span className="mdi mdi-keyboard-backspace btn btn-back text-white btn-md mx-1 r4"></span>
                  <span className="mdi mdi-delete btn btn-danger text-white btn-md mx-1 r4" title="Delete"></span>
                </td>
              </tr>
            </tbody>
          </table>
            </div>
            </div>
          </div>   
          <Modal
        show={createReturnOrderModal}
        size="md"
        onHide={closeReturnOrderModal}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ width: "100%", borderRadius: "50px" }}
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
        <CreateReturnOrder/>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ReturnOrders; 