import React from "react";
import '../../../App.scss';
import Navbar from '../../../components/layout/admin/Navbar';
// import POSSidebar from '../../../components/layout/admin/POSSidebar';
import POS from "../../../components/Admin/POS/POS";
import { useEffect, useState } from "react";

const POSView = () => {
const [localStorageData, setLocalStorageData] = useState();
  // get cart from local storage
  const getCart = () => {
    let cart = [];
    if (localStorage.getItem("cart") !== null) {
      cart = JSON.parse(localStorage.getItem("cart"));
      setLocalStorageData(cart);
    }
    return cart;
  };

  useEffect (() => {
    getCart();
  }, []);

  const handleCallback = (data) => {
    setLocalStorageData(data);
    // console.log('okay',data);
  };
 

  return (
    <>
      <div className="container-scroller">
      {/* <Navbar parentCallback = {handleCallback} /> */}
        <POS localStorageData={localStorageData} /> 
    </div>
    </>
  );
};
export default POSView;
