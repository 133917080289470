import request from 'umi-request';
import { API_URL, USER_TOKEN } from '../config/config';

export async function getAllSales(userId, ref) {
  return request(`${API_URL}/api/sale/${userId}?businessRef=${ref}`, {
    method: 'get',
    headers: {
       'Content-Type': 'application/json',
       'Authorization': USER_TOKEN,
    },
  });
}


export async function getSale(userId, id) {
  return request(`${API_URL}/api/sale/?id=${id}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
    // params: {
    //   userId: userId,
    //   id: id,
    // },
  });
}

export async function createWalkinSale(body) {
  return request(`${API_URL}/api/sale`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
    body: JSON.stringify(body),
  });
}

export async function createCustomerSale(body) {
  return request(`${API_URL}/api/sale/customer`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
    body: JSON.stringify(body),
  });
}

export async function updateSale(body) {
  return request(`${API_URL}/api/sale/customer`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
    body: JSON.stringify(body),
  });
}


export async function deleteSale(userId, customerId, ref) {
  return request(`${API_URL}/api/sale/${userId}?id=${customerId}&ref=${ref}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
    params: {
      id: userId,
      ref: ref,
    },
  });
}
