import React, { useState, useEffect } from 'react'
import '../../../App.scss';
import { Country } from 'country-state-city';
import { createUser } from '../../../services/UserService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUser } from '../../../config/common';

const CreatUser = () => {
  const items = Country.getAllCountries();
  console.log(items);
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({
    name: '',
    companyName: '',
    email: '',
    accountType: 'business',
    password: '',
    confirmPassword: '',
    accountTitle: 'manager',
  });

  console.log('user', user)
  const createUserAction = async () => {
    try {
      setFormState({ ...formState });
      const body = {
        full_name: formState.name,
        company: formState.companyName,
        email: formState.email,
        password: formState.password,
        account: {
          type: formState.accountType,
          title: formState.accountTitle,
        }
      }
      if (body.name === '' || body.email === '' || body.password === '') {
        toast.error('Please fill all the fields');
        return;
      }
      const response = await createUser(body);
      console.log('response', response);
      if (response.status === 'ok') {
        toast.success("User created successfully");
      }

      setFormState({
        name: '',
        companyName: '',
        email: '',
        accountType: 'business',
        password: '',
        confirmPassword: '',
        accountTitle: 'manager',
      });
    } catch (err) {
      toast.error("Error, please try again later");
      setFormState({ ...formState });
    }
    // console.log(body)
  };

  const updateForm = (e) => {
    const { value, name } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
    console.log(value)
  };
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const user = await getUser();
      if (user) {
        setUser(user);
        setLoading(false);
      }
    }
    fetchData();

  }, []);
  return (
    <div className='row m-0 '>
      <ToastContainer />
      <div className="col-md-12 col-xl-12 mx-auto input-container">
        <div className='card-body'>

          <h6 className=" mb-4 col-12"><span>
            <i className="mdi mdi-account-plus"></i>
          </span> Create User </h6>

          <div className="col-12  mt-3">
            <label htmlFor="" className='mb-0'>Name:</label>
            <input type="text"
              className="form-control input shadow-sm" name="name" value={formState?.name}
              onChange={updateForm} id="" aria-describedby="helpId" placeholder="" />
          </div>

          <div className="col-12 mt-2">
            <label htmlFor="" className='mb-0'>Phone Number:</label>
            <input type="text"
              className="form-control input shadow-sm" name="phoneNumber" value={formState?.phoneNumber}
              onChange={updateForm} id="" aria-describedby="helpId" placeholder="" />
          </div>

          <div className="col-12 mt-2">
            <label htmlFor="" className='mb-0'>Email:</label>
            <input type="text"
              className="form-control input shadow-sm" name="emailAddress" value={formState?.emailAddress}
              onChange={updateForm} id="" aria-describedby="helpId" placeholder="" />
          </div>

          <div className="col-12 mt-2">
            <label htmlFor="" className='mb-0'>User Name:</label>
            <input type="text"
              className="form-control input shadow-sm" name="username" value={formState?.username}
              onChange={updateForm} id="" aria-describedby="helpId" placeholder="" />
          </div>

          <div className="col-12 mt-2">
            <label htmlFor="" className='mb-0'>Address:</label>
            <input type="text"
              className="form-control input shadow-sm" name="address" value={formState?.address}
              onChange={updateForm} id="" aria-describedby="helpId" placeholder="" />
          </div>

          <div className="col-12 mt-2">
            <div className="row m-0 p-0">
              <div className="col-5 mr-auto mt-2 p-0">
                <label htmlFor="" className='mb-0'>Date of Birth:</label>
                <input type="date"
                  className="form-control input shadow-sm" name="dob" value={formState?.dob}
                  onChange={updateForm} id="" aria-describedby="helpId" placeholder="" />
              </div>

              <div className="col-5 ml-auto mt-2 p-0">
                <label htmlFor="" className='mb-0 '>Select Location:</label>
                <select className="form-control custom-select input shadow-sm" name="location" value={formState?.location}
                  onChange={updateForm} id="">
                  <option >1</option>
                  <option>2</option>
                  <option>3</option>
                </select>
              </div>
            </div>
          </div>

          <div className="col-12 mt-2">
            <div className="row m-0 p-0">
              <div className="col-5 mr-auto mt-2 p-0">
                <label htmlFor="" className='mb-0'>Password:</label>
                <input type="password"
                  className="form-control input shadow-sm" name="password" value={formState?.password} onChange={updateForm} id="" aria-describedby="helpId" placeholder="" />
              </div>

              <div className="col-5 ml-auto mt-2 p-0">
                <label htmlFor="" className='mb-0 '>Confirm Password:</label>
                <input type="password"
                  className="form-control input shadow-sm" name='confirmPassword' value={formState?.confirmPassword} onChange={updateForm} id="" aria-describedby="helpId" placeholder="" />
              </div>
            </div>
          </div>

          <div className="col-12 mt-2">
            <label htmlFor="" className='mb-0'>Select User Role:</label>
            <select name='role' value={formState?.role} onChange={updateForm} className="form-control custom-select input shadow-sm" id="">
              <option >1</option>
              <option>2</option>
              <option>3</option>
            </select>
          </div>
          <div className="col-12 mt-3">
            <button type="button" className="btn btn-default rounded-x  border rounded-x shadow">Cancel</button>
            <button type="button" onClick={() => createUserAction()} className="btn btn-primary rounded-x shadow float-right">Create<i className="mdi mdi-plus" aria-hidden="true"></i></button>
          </div>

        </div>
      </div>

    </div>
  )
}

export default CreatUser;  