import request from 'umi-request';
import { API_URL, USER_TOKEN } from '../config/config';

export async function getAllCustomers(userId, ref) {
  return request(`${API_URL}/api/customer`, {
    method: 'get',
    headers: {
       'Content-Type': 'application/json',
       'Authorization': USER_TOKEN,
    },
  });
}

export async function createCustomer(body) {
  return request(`${API_URL}/api/customer/`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
    body: JSON.stringify(body),
  });
}

export async function updateCustomer(body, customerId) {
  return request(`${API_URL}/api/customer?id=${customerId}`, {
    method: 'patch',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
    body: JSON.stringify(body),
  });
}


export async function deleteCustomer(userId, customerId, ref) {
  return request(`${API_URL}/api/customer/${userId}?id=${customerId}&ref=${ref}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': USER_TOKEN,
    },
    params: {
      id: userId,
      ref: ref,
    },
  });
}
