import React, { useState, useEffect } from 'react'
import '../../../App.scss';
import { Link } from "react-router-dom";
import { getAllSales } from "../../../services/SaleService";
import { getUser } from "../../../config/common";

const Sales = () => {
  const [sales, setSales] = useState([]);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState([]);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const user = await getUser();
      if (user) {
        const userId = user._id;
        const response = await getAllSales(userId, user.businessRef);
        setSales(response.data);
        setUser(user);
        setLoading(false);

      }
    }
    fetchData();

  }, []);

  return (
    <div className='m-0 '>
      <h6 className=" mb-4 col-12"><span>
        <i className="mdi mdi-account-multiple "></i>
      </span> Sales </h6>

      <div className=" heads shadow-sm border h-75  p-3">
        <hr className='shadow mt-0 pt-0 mt-2' />
        <div className="body">
          <div className="header text-right">
            <span className="mdi db-query mdi-magnify"></span>
            <input type="text" className='db-search pl-4' />
          </div>

          <div className="views mt-3 shadow-sm rounded-lg">
            <div className=' table-responsive'>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Customer Name</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Payment Type</th>
                    <th scope="col">Created By</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {sales.map((sale, index) => (
                    <tr key={index}>
                      <th scope="row"><input type={'checkbox'}></input></th>
                      <td>{sale.delivery_details.recipient.name || 'Walkin Sale'}</td>
                      <td>{sale.payment_method[0].amount}</td>
                      <td>{sale.payment_method[0].type}</td>
                      <td>{sale.staff.name}</td>
                      <td>
                        <span className="mdi mdi-pencil btn btn-primary text-white btn-md mx-1 r4" title="Edit"></span>
                        <span className="mdi mdi-keyboard-backspace btn btn-back text-white btn-md mx-1 r4"></span>
                        <span className="mdi mdi-delete btn btn-danger text-white btn-md mx-1 r4" title="Delete"></span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

        </div>



      </div>

    </div>
  )
}

export default Sales; 