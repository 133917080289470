import React, { useState, useEffect } from "react";
import "../../../App.scss";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import CreatCustomer from "./CreateCustomers";
import { deleteCustomer, getAllCustomers } from "../../../services/CustomerService";
import { getUser } from '../../../config/common';
const Customers = () => {
  const [show, setShow] = useState(false);
  const [createCustomerModal, setCreateCustomerModal] = useState(false);
  const [editCustomerModal, setEditCustomerModal] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [customerData, setCustomerData] = useState({});
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const showFundCustomerModal = () => setShow(true);
  const closeCustomerModal = () => setCreateCustomerModal(false);
  const showCreateCustomerModal = () => setCreateCustomerModal(true);
  useEffect(() => {
    async function fetchData() {
        setLoading(true);
        const user = await getUser();
        if (user) {
            const userId = user._id;
            const response = await getAllCustomers(userId, user.businessRef);
            setCustomers(response.data);
            setUser(user);
            setLoading(false);

        }
    }
    fetchData();

}, []);

const removeCustomer = async (id) => {
  const user = await getUser();
  const result = window.confirm("Are you sure you want to delete this customer?");
  if (result) {
  if (user) {
    await deleteCustomer(id);
      const newCustomer = customers.filter(customer => customer._id !== id);
      setCustomers(newCustomer);
  }
  }
};

  const showEditModal = (data) => {
    setEditCustomerModal(true);
    setCustomerData(data);
  };

  return (
    <div className="m-0">
      <h6 className=" mb-4 col-12">
        <span>
          <i className="mdi mdi-account-multiple "></i>
        </span>{" "}
        Customers{" "}
      </h6>

      <div className=" heads shadow-sm border h-75  p-3">
        <div className="  text-right  ">
          <Link
            to="#"
            onClick={showCreateCustomerModal}
            className="btn btn-primary mr-2 r4 text-white btn-sm p-2 px-4"
          >
            {" "}
            Add Customer{" "}
          </Link>
        </div>
        <hr className="shadow mt-0 pt-0 mt-2" />
        <div className="body">
          <div className="header text-right">
            <span className="mdi db-query mdi-magnify"></span>
            <input type="text" className="db-search pl-4" />
          </div>
          <div className="views mt-3 shadow-sm rounded-lg">
            {customers.length === 0 ? (
              <div className="empty-view" style={{textAlign: 'center'}}>
                <div className="title">No Customers</div>
                <div className="description">
                  You have no customers. You can add a customer by clicking the
                  button above.
                </div>
              </div>
            ) : (
              <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Name</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Email</th>
                  <th scope="col">Account Balance</th>
                  <th scope="col">Loyalty Card</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {customers.map((customer) => (
                <tr key={customer._id}>
                  <th scope="row"><input type={'checkbox'}></input></th>
                  <td>{customer.full_name}</td>
                  <td>{customer.phone}</td>
                  <td>{customer.email}</td>
                  <td>{customer.account.account_balance}</td>
                  <td>{customer.account.loyalty_card}</td>
                  <td>
                    <span className="mdi mdi-pencil btn btn-primary text-white btn-md mx-1 r4" title="Edit"
                    onClick={() => showEditModal(customer)}></span>
                    <span
                      className="mdi mdi-credit-card btn btn-warning text-white btn-md mx-1 r4"
                      onClick={showFundCustomerModal}
                    ></span>
                    <span onClick={() => removeCustomer(customer._id)} className="mdi mdi-delete btn btn-danger text-white btn-md mx-1 r4" title="Delete"></span>
                  </td>
                </tr>
              ))} 
              </tbody>
            </table>
            </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ width: "100%", borderRadius: "50px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Funds</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="col-12  mt-3">
            <label htmlFor="" className='mb-0'>Select Payment Option:</label>
            <select className="form-control custom-select input shadow-sm" name="" id="">
                <option >Select one</option>
                <option value={'Cash Payment'}>Cash Payment</option>
                <option value={'Transfer Payment'}>Transfer Payment</option>
              </select>  
          </div> 

          <div className="col-12  mt-3">
            <label htmlFor="" className='mb-0'>Enter Amount:</label>
            <input type="number"
              className="form-control input shadow-sm" name="" id="" aria-describedby="helpId" placeholder=""/> 
          </div> 

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary">Add</Button>
        </Modal.Footer>
      </Modal>


      <Modal
        show={createCustomerModal}
        onHide={closeCustomerModal}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="md"
        style={{ width: "100%", borderRadius: "50px" }}
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>

        <CreatCustomer type={'create'}/>

        </Modal.Body>
      </Modal>


      <Modal
        show={editCustomerModal}
        onHide={() => setEditCustomerModal(false)}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="md"
        style={{ width: "100%", borderRadius: "50px" }}
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>

        <CreatCustomer type={'update'} data={customerData}/>

        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Customers;
