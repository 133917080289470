import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Trans } from "react-i18next";
import { getAllProducts } from "../../../services/ProductService";
// import { history } from "umi";

class POSSidebar extends Component {

  state = { products: [], categories: [] };

  navigateToDashboard() {
    document.body.classList.toggle("marginToggle");
    document.body.classList.toggle("sidebar-icon-only");
    this.props.history.push("/dashboard");
  }

  navigateToPos(cart) {
    document.body.classList.toggle("marginToggle");
    document.body.classList.toggle("sidebar-icon-only");
    this.props.history.push(`/pos?=${cart}`);

  }

  getProducts() {
    getAllProducts()
      .then((response) => {
        this.setState({ products: response.data || [] });
        setTimeout(() => {
          const categories = [...new Set(this.state.products.map((product) => product.category))];
          this.setState({ categories });
        }
          , 0);
      }
      )
      .catch((error) => {
        console.log(error);
      }
      );
  }

  componentDidMount() {
    this.getProducts();
  }
  

  render() {
    return (
      
      <nav
        className="sidebar sidebar-offcanvas dbg scrollable-div"
        id="sidebar"
        style={{ minHeight: "10px", maxHeight: "calc(110vh - 20px)", width: '150px', marginTop: '50px', fontSize: '12px' }}
      >
        <ul className="nav p-0 pt-3 get">

          <li
            className={
              this.isPathActive("/dashboard") ? "nav-item active" : "nav-item"
            }
          >
            <Link className="nav-link" to="/dashboard">
              <i style={{ fontSize: '12px' }} className="mdi mdi-home-outline menu-icon"></i>
              <span className="" style={{ fontSize: '12px' }}>
                <Trans> Dashboard</Trans>
              </span>
            </Link>
          </li>
          {this.state.categories.map((cart, index) => (
            <li key={index}
              className={
                this.isPathActive("/dashboard") ? "nav-item active" : "nav-item"
              }
            >
              <Link onClick={() => this.navigateToPos(cart)} className="nav-link">
                <i style={{ fontSize: '12px' }} className="mdi mdi-cart-outline menu-icon"></i>
                <span className="" style={{ fontSize: '12px' }}>
                  <Trans autoCapitalize> {cart.toUpperCase()}</Trans>
                </span>
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

}

export default withRouter(POSSidebar);
