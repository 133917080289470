import React, { Component } from "react";
import "../../../assets/css/dashboard.css";
import { Link } from 'react-router-dom';

export class MainSales extends Component {
  
  render() {

    return (
      <div>
        <div className="page-header">
          <h3 className="page-title">
            <span className="">
              <i className="mdi mdi-home"></i>
            </span>
            Sales
          </h3>
          <nav aria-label="breadcrumb"></nav>
        </div>
        {/*   Overview Start */}

        <div className="col-md-12">
            <div className="row">
                   
            <div class="col-md-4 card sales-card">
                <Link className="nav-link" to="/sales/list-sales">
                  <img
                    src={require("../../../assets/images/POS/bill.svg")}
                    className="img-responsive sales-image"
                    alt="total sales"
                  /><br></br>
                  <p>List Sales</p>
                  </Link>
                </div>

                
                <div class="col-md-4 card sales-card">
                  <img
                    src={require("../../../assets/images/POS/add.svg")}
                    className="img-responsive sales-image"
                    alt="total sales"
                  /><br></br>
                  <p>Add Sales</p>
                </div>

                
                <div class="col-md-4 card sales-card">
                <Link className="nav-link" to="/sales/main-sales">
                  <img
                    src={require("../../../assets/images/POS/bill.svg")}
                    className="img-responsive sales-image"
                    alt="total sales"
                  /><br></br>
                  <p>List Orders</p>
                  </Link>
                </div>
                

                <div class="col-md-4 card sales-card">
                  <img
                    src={require("../../../assets/images/POS/add.svg")}
                    className="img-responsive sales-image"
                    alt="total sales"
                  /><br></br>
                    <p>Add Order</p>
                </div>


                <div class="col-md-4 card sales-card">
                  <img
                    src={require("../../../assets/images/POS/bill.svg")}
                    className="img-responsive sales-image"
                    alt="total sales"
                  /><br></br>
                  <p>List Deliverables</p>
                </div>

                
              </div>
            </div>

        {/*   Overview Ends */}
      </div>
    );
  }
}
export default MainSales;
